<template>
  <div class="reset">
    <div class="back" @click="this.$store.commit('SET_FORGOT_PASSWORD', 0)">
      <img src="@/assets/svg/v2/arrowToLeft.svg" alt="Back" class="themed-img"/>
      <p>{{ $t('commons.back') }}</p>
    </div>

    <h1>{{ $t('login.forgot.title') }}</h1>
    <p class="subtitle">{{ $t('login.forgot.subtitle') }}</p>

    <form @submit.prevent="checkEmail()" class="reset-form">
      <div class="reset-container">
        <label for="email">
          <h4>{{ $t('login.forgot.email') }}</h4>
          <el-input
            class="input"
            type="email"
            minlength="5"
            v-model="reset.email"
            :class="{ errorInput: regex.email }"
            focus
            :placeholder="$t('register.email')"
            clearable
            autocomplete="username"
          />
        </label>
      </div>

      <div class="continue-reset">
        <button class="reset-connect">{{ $t('commons.continue') }}</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reset: {
        email: null,
      },

      regex: {
        email: false,
      },
    }
  },
  methods: {
    checkEmail() {
      const regex_email =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!regex_email.test(this.reset.email)) {
        this.regex.email = true
      } else {
        this.regex.email = false
        this.$store.dispatch('sendEmailCodeResetPass', this.reset)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/auth.scss';

.reset,
.back,
.reset-container label,
.reset-form {
  display: flex;
}

.reset {
  flex-direction: column;
  margin-top: 7%;

  .back {
    cursor: pointer;
    color: var(--subtitle-text-color);
    font-size: 15px;
    margin-bottom: 42px;
  }

  h1 {
    margin-bottom: 13px;
  }

  .subtitle {
    color: var(--subtitle-text-color);
    margin-bottom: 34px;
    width: 500px;
    font-size: 15px;
  }
}

.reset-container {
  label {
    flex-direction: column;

    h4 {
      color: var(--text-color);
      font-weight: 600;
      margin-bottom: 8px;
    }
  }
}

.reset-form {
  flex-direction: column;
}

.reset-connect {
  display: flex;
  width: 492px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 52px;
  background: $primary-color;
  margin-top: 60px;
}

.input {
  border: 1px solid var(--border) !important;
  border-radius: 4px !important;
}

@media screen and (max-width: 500px) {
  .back {
    margin-top: 20px;
  }

  .reset {
    margin: 0 10px;
  }

  .subtitle {
    max-width: 350px;
  }

  .reset-connect {
    width: 200px;
    margin: auto;
    margin-top: 30px;
  }
}
</style>
