import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/auth';
import Home from '@/views/Home.vue';
import Register from '@/views/auth/Register_V2.vue';
import Login from '@/views/auth/Login_V2.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: false
    }
  },
  {
    path: '/presentation',
    name: 'Videos',
    component: () => import('@/views/Videos.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      auth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      auth: false
    }
  },
  {
    path: '/auth/portfolio-management',
    name: 'portfolio',
    component: () => import('@/views/modules/portfolio/Portfolio_V2.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/newsfeed',
    name: 'newsfeed',
    component: () => import('@/views/Newsfeed.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/Blog.vue')
  },
  {
    path: '/bottler/:name',
    name: 'bottler',
    component: () => import('@/views/Bottler.vue')
  },
  {
    path: '/blog/:url',
    name: 'article',
    component: () => import('@/views/Article.vue'),
    props: true
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('@/views/Subscriptions.vue')
  },
  {
    path: '/subscriptions/choice/:type/:duration',
    name: 'subscriptionsChoice',
    component: () => import('@/views/SubscriptionsChoice.vue'),
  },
  {
    path: '/subscriptions/payment',
    name: 'subscriptionsPayment',
    component: () => import('@/views/SubscriptionsPayment.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/auth/storage',
    name: 'storage',
    component: () => import('@/views/connected/Storage.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/auth/account/details',
    name: 'accountDetails',
    component: () => import('@/views/connected/AccountDetails_V2.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/auth/account/wallet',
    name: 'accountWallet',
    component: () => import('@/views/connected/Wallet.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/auth/notifications',
    name: 'notifications',
    component: () => import('@/views/connected/Notifications.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/auth/matchs',
    name: 'matchs',
    component: () => import('@/views/modules/matchs/Matchs.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/order-renew/:key',
    name: 'orderRenew',
    component: () => import('@/views/OrderRenew.vue')
  },
  {
    path: '/auth/order-list',
    name: 'orderList',
    component: () => import('@/views/connected/OrdersList.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/auth/order-alert',
    name: 'orderAlert',
    component: () => import('@/views/connected/OrdersAlerts.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/auth/reviews',
    name: 'reviews',
    component: () => import('@/views/connected/Reviews.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/order/payment',
    name: 'orderPayment',
    component: () => import('@/views/OrderPayment.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/product/order/informations',
    name: 'orderInformations',
    component: () => import('@/views/modules/buysell/OrderInformations.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/auth/profile',
    name: 'profile',
    component: () => import('@/views/connected/Profile.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/auth/transactions/history',
    name: 'transactionsHistory',
    component: () => import('@/views/connected/TransactionsHistory.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/search-products',
    name: 'searchProducts',
    component: () => import('@/views/Search_V2.vue')
  },
  {
    path: '/product/:isbn',
    name: 'productPage',
    component: () => import('@/views/modules/buysell/ProductPage.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/legal/mangopay',
    name: 'legalMangopay',
    component: () => import('@/views/legal/mangopay.vue'),
  },
  {
    path: '/legal/tep',
    name: 'legalTEP',
    component: () => import('@/views/legal/tep.vue'),
  },
  {
    path: '/legal/privacy',
    name: 'privacy',
    component: () => import('@/views/legal/privacy.vue'),
  },
  {
    path: '/:wrongPath(.*)',
    redirect: () => {
      return { name: 'Home' };
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && store.state.auth === false) {
    next('/login');
  } else {
    next();
  }
});

export default router;