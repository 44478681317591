<template>
  <div class="file-dropped-img">
    <img src="@/assets/svg/v2/registerProcess/image.svg" alt="File" class="themed-img" />
  </div>
  <div class="file-dropped-informations">
    <p>{{ file.name }}</p>
    <div>
      <p>{{ parseFloat(file.size / 1000000).toFixed(2) }} Mb</p>
      <p class="sep">•</p>
      <p>{{ file.type.replace('image/', '').toUpperCase() }}</p>
    </div>
  </div>
  <button
    class="file-dropped-delete"
    @click.prevent="$emit('deleteFile', file)"
  >
    <img src="@/assets/svg/v2/registerProcess/delete.svg" class="themed-img" alt="Delete file" />
  </button>
</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/auth.scss';
.file-dropped-img {
  margin-right: 16px;
  margin-bottom: 0 !important;

  img {
    background: $primary-color;
    border-radius: 50%;
    padding: 7px;
  }
}

.file-dropped-informations {
  flex-direction: column;
  width: 100%;
  margin-bottom: 0 !important;

  p {
    color: var(--text-color);
    font-size: 15px;
    font-weight: 500;
  }

  div {
    display: flex;
    align-items: center;
    color: var(--subtitle-text-color);
    margin-bottom: 0 !important;
    width: 290px;

    p {
      color: var(--subtitle-text-color);
      margin-top: 4px;
    }

    .sep {
      margin: auto 5px;
    }
  }
}

.file-dropped-delete {
  display: flex;
  justify-content: right;
  align-items: center;
  background: transparent;
  border: 0;
}

@media screen and (max-width: 500px) {
  .file-dropped-img {
    display: none;
  }
}
</style>
