<template>
  <div class="register-step-0">
    <div class="register-account-type-choice">
      <h1>{{ $t('commons.registration') }}</h1>
      <p class="subtitle">{{ $t('register.step_0.subtitle') }}</p>
    </div>

    <div class="register-form-account-choice">
      <h3>{{ $t('register.step_0.usage') }}</h3>
      <div>
        <div @click="accountTypeStatus('personnal')" class="bloc-choice">
          <div class="is-choice-selected">
            <img
              v-if="accountType === null || accountType === 1"
              src="@/assets/svg/v2/dot.svg"
              class="themed-current"
              alt="Personnal"
            />
            <img
              v-else-if="accountType === 0"
              src="@/assets/svg/v2/dotActive.svg"
              alt="Personnal active"
            />
          </div>
          <img
            v-if="accountType === null || accountType"
            width="24"
            height="24"
            src="@/assets/svg/v2/personnal.svg"
            class="themed-img"
            alt="Personnal account"
          />
          <img
            v-else-if="!accountType"
            width="24"
            height="24"
            src="@/assets/svg/v2/personnalActive.svg"
            alt="Personnal account"
          />
          <h5>{{ $t('register.step_0.individual') }}</h5>
          <p>{{ $t('register.step_0.individual_infos') }}</p>
        </div>
        <div @click="accountTypeStatus('pro')" class="bloc-choice">
          <div class="is-choice-selected">
            <img
              v-if="accountType === null || accountType === 0"
              src="@/assets/svg/v2/dot.svg"
              class="themed-current"
              alt="Pro"
            />
            <img
              v-else-if="accountType === 1"
              src="@/assets/svg/v2/dotActive.svg"
              alt="Pro active"
            />
          </div>
          <img
            v-if="accountType === null || !accountType"
            width="24"
            height="24"
            src="@/assets/svg/v2/pro.svg"
            class="themed-img"
            alt="Pro account"
          />
          <img
            v-else-if="accountType"
            width="24"
            height="24"
            src="@/assets/svg/v2/proActive.svg"
            alt="Pro account"
          />
          <h5>{{ $t('register.step_0.professionnal') }}</h5>
          <p>{{ $t('register.step_0.professionnal_infos') }}</p>
        </div>
      </div>
      <p class="warning-account-change">
        {{ $t('register.step_0.account_no_change') }}
      </p>
    </div>

    <button @click="selectAccountType()" :disabled="accountType === null">
      {{ $t('commons.continue') }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountType: null,
    }
  },
  methods: {
    accountTypeStatus(status) {
      if (status === 'pro') {
        this.accountType = 1
      } else {
        this.accountType = 0
      }
    },
    selectAccountType() {
      this.$emit('accountTypeSelect', this.accountType)
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/auth.scss';

.register-step-0,
.register-form-account-choice div,
.bloc-choice,
.is-choice-selected {
  display: flex;
}

.register-step-0 {
  height: 800px;
  flex-direction: column;
  align-items: center;

  h3 {
    color: var(--text-color);
    font-size: 15px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    color: var(--text-color);
  }

  .register-account-type-choice {
    width: 492px;

    h1 {
      margin-top: 74px;
      margin-bottom: 12px;
      color: var(--text-color);
    }

    p {
      width: 460px;
      margin-bottom: 35px;
    }
  }

  .register-form-account-choice {
    width: 492px;

    div {
      width: 100%;

      .bloc-choice:nth-child(1) {
        margin-right: 16px;
      }

      .bloc-choice {
        flex-direction: column;
        border: 1px solid var(--border);
        background: var(--background-color-2);
        padding-top: 11px;
        padding-right: 11px;
        padding-left: 15px;
        border-radius: 12px;
        margin-top: 11px;
        margin-bottom: 16px;
        cursor: pointer;

        .is-choice-selected {
          justify-content: flex-end;
        }

        h5 {
          color: var(--text-color);
          font-size: 15px;
          font-weight: 500;
          margin-top: 8px;
        }

        p {
          margin-top: 3px;
          margin-bottom: 17px;
        }
      }
    }
  }

  button {
    display: flex;
    width: 492px;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 52px;
    background: $primary-color;
    border: none;
    color: $light-color;
    margin-top: 78px;
  }
}

@media screen and (max-width: 500px) {
  .register-step-0 {
    .register-account-type-choice {
      width: auto;
      margin: 15px;

      h1 {
        text-align: center;
      }

      p {
        width: auto;
        text-align: center;
      }
    }

    button {
      width: 200px;
      margin-top: 20px;
    }
  }

  .register-form-account-choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto !important;

    div {
      flex-wrap: wrap;
      max-width: 240px !important;

      .bloc-choice {
        margin-right: 0 !important;
      }
    }

    h3 {
      text-align: center;
    }

    .warning-account-change {
      text-align: center;
      margin: 15px;
    }
  }
}
</style>
