<template>
  <div class="home" id="home">
    <!-- <el-backtop :right="30" :bottom="30" /> -->

    <div class="home-heading">
      <h1>{{ $t('home.title') }}</h1>
    </div>
    <img
      class="home-banner"
      src="@/assets/svg/v2/home/homeBanner.svg"
      alt="Banner image"
    />

    <img
      class="home-background"
      src="@/assets/svg/v2/home/background.svg"
      alt="TEP home background"
    />

    <div class="search-bar">
      <search :isHome="true"></search>
    </div>

    <div class="checked-items">
      <div class="secure-transactions">
        <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item"/>
        <p>{{ $t('home.secure_transactions') }}</p>
      </div>
      <div class="low-fees">
        <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item"/>
        <p>{{ $t('home.fees') }}</p>
      </div>
      <div class="verified-accounts">
        <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item"/>
        <p>{{ $t('home.verified_accounts') }}</p>
      </div>
    </div>

    <div class="trading-without-subscriptions">
      <h2>{{ $t('home.without_subscriptions') }}</h2>
    </div>

    <div class="bloc-create-account">
      <p class="bca-intro">{{ $t('home.intro') }}</p>
      <p class="bca-intro">{{ $t('home.create_account_subtitle') }}</p>
      <button class="generic-btn" @click="redirect('/register')">
        <p>{{ $t('home.create_account') }}</p>
        <img src="@/assets/svg/v2/home/right.svg" alt="Create account" />
      </button>
    </div>

    <subscribeNewsletter :row="true" />

    <div class="buy-sell">
      <div class="title-size">
        <a @click="$router.push('/search-products')">
          <h3 class="hidden-desktop">
            {{ $t('home.buy_and_sell') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            /></h3>
        </a>
      </div>
      <video
        v-if="!isLoading"
        id="video-presentation-trading"
        width="400px"
        height="280px"
        controls
      >
        <source
          :src="`https://w3d4e4y6bt9n62nh8v75.s3.eu-west-3.amazonaws.com/presentation_${returnLocale}.mp4`"
          type="video/mp4"
        />
      </video>
      <div>
        <a @click="$router.push('/search-products')">
          <h3 class="hidden-tel">
            {{ $t('home.buy_and_sell') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            /></h3>
        <p>{{ $t('home.buy_and_sell_description') }}</p></a>
      </div>
    </div>

    <!-- <div class="swap">
      <div>
        <a @click="$router.push('/search-products')">
          <h3 class="hidden-tel">
            {{ $t('home.swap') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            /></h3
        ></a>
        <p>{{ $t('home.swap_description') }}</p>
      </div>
      <img src="@/assets/svg/v2/home/infosSwap.svg" alt="Swap" />
      <div>
        <a @click="$router.push('/search-products')">
          <h3 class="hidden-desktop">
            {{ $t('home.swap') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            /></h3
        ></a>
      </div>
    </div> -->

    <div class="blocktrade">
      <div class="title-size">
        <a @click="$router.push('/search-products')">
          <h3 class="hidden-desktop">
            {{ $t('home.blocktrade') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            /></h3
        ></a>
      </div>
      <video
        v-if="!isLoading"
        id="video-presentation-blocktrade"
        width="400px"
        height="280px"
        controls
      >
        <source
          :src="`https://w3d4e4y6bt9n62nh8v75.s3.eu-west-3.amazonaws.com/blocktrade_en-GB.mp4`"
        />
      </video>
      <div>
        <a @click="$router.push('/search-products')">
          <h3 class="hidden-tel">
            {{ $t('home.blocktrade') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            /></h3>
        <p>{{ $t('home.blocktrade_description') }}</p></a>
      </div>
    </div>

    <div class="storage">
      <div>
        <a @click="$router.push('/auth/storage')">
          <h3 class="hidden-tel">
            {{ $t('home.storage') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Storage"
              class="link-title themed-img"
            /></h3
        >
        <p>{{ $t('home.storage_description') }}</p></a>
      </div>
      <img src="@/assets/img/storage.png" alt="Storage" class="storage-img" />
      <div class="hidden-desktop">
        <a @click="$router.push('/search-products')">
          <h3 class="hidden-desktop">
            {{ $t('home.storage') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Storage"
              class="link-title themed-img"
            /></h3
        ></a>
      </div>
    </div>

    <div class="newsfeed">
      <div class="hidden-desktop">
        <a @click="$router.push('/Newsfeed')">
          <h3>
            {{ $t('home.newsfeed') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            />
          </h3>
        </a>
      </div>
      <img src="@/assets/img/newsfeed.webp" alt="Newsfeed" />
      <div>
        <a @click="$router.push('/Newsfeed')">
          <h3 class="hidden-tel">
            {{ $t('home.newsfeed') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            />
          </h3>
        <p>{{ $t('home.newsfeed_description') }}</p></a>
      </div>
    </div>

    <div class="blog">
      <div class="hidden-desktop">
        <a
          @click.prevent="
            $router.push({ path: '/Newsfeed', query: { category: 'blog' } })
          "
        >
          <h3>
            {{ $t('home.blog_and_review') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Blog"
              class="link-title themed-img"
            />
          </h3>
        </a>
      </div>
      <img src="@/assets/img/blog-review.webp" alt="Blog and review" />
      <div>
        <a
          @click.prevent="
            $router.push({ path: '/Newsfeed', query: { category: 'blog' } })
          "
        >
          <h3 class="hidden-tel">
            {{ $t('home.blog_and_review') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Blog"
              class="link-title themed-img"
            />
          </h3>
        <p>{{ $t('home.blog_and_review_description') }}</p></a>
      </div>
    </div>

    <div class="events">
      <div class="hidden-desktop">
        <a
          @click="
            $router.push({ path: '/Newsfeed', query: { category: 'events' } })
          "
        >
          <h3>
            {{ $t('home.events_browser') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Events"
              class="link-title themed-img"
            />
          </h3>
        </a>
      </div>
      <img src="@/assets/img/events.webp" alt="Events" />
      <div>
        <a
          @click="
            $router.push({ path: '/Newsfeed', query: { category: 'events' } })
          "
        >
          <h3 class="hidden-tel">
            {{ $t('home.events_browser') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Events"
              class="link-title themed-img"
            />
          </h3>
        <p>{{ $t('home.events_browser_description') }}</p></a>
      </div>
    </div>

    <div class="portfolio-price-history">
      <div class="price-history">
        <div class="elem1">
          <img
            src="@/assets/img/price_history_whisky.webp"
            alt="Price history"
          />
        </div>
        <a @click="$router.push('/search-products')">
          <h3 class="elem2">
            {{ $t('home.price_history') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            />
          </h3>
        
        <p class="elem3">{{ $t('home.price_history_description') }}</p></a>
      </div>
      <div class="portfolio">
        <div class="elem1">
          <img src="@/assets/img/portfolio_n.webp" alt="Portfolio" />
        </div>
        <a @click="$router.push('/auth/portfolio-management')">
          <h3 class="elem2">
            {{ $t('home.portfolio') }}
            <img
              src="@/assets/svg/v2/home/right.svg"
              alt="Navigate to Newsfeed"
              class="link-title themed-img"
            />
          </h3>
        <p class="elem3">{{ $t('home.portfolio_description') }}</p></a>
      </div>
    </div>

    <el-divider class="divider-guarantee" />

    <div class="guarantee">
      <h2>{{ $t('home.title_3') }}</h2>

      <div class="guarantee-bloc">
        <div class="first-guarantee-bloc">
          <div
            class="low-fees mobile-card"
            @click="handleCardClick"
            v-show="isMobile && currentIndex === 0"
          >
            <div>
              <img src="@/assets/svg/v2/home/lowFees1.svg" alt="Low fees" />
              <img src="@/assets/svg/v2/home/lowFees2.svg" alt="Low fees" />
            </div>
            <h3>{{ $t('home.low_fees') }}</h3>
            <p>{{ $t('home.low_fees_description') }}</p>
          </div>

          <div
            class="verified-accounts mobile-card"
            @click="handleCardClick"
            v-show="isMobile && currentIndex === 1"
          >
            <div>
              <img
                src="@/assets/svg/v2/home/verifiedAccount.svg"
                class="themed-img"
                alt="Verified account"
              />
            </div>
            <h3>{{ $t('home.verified_accounts') }}</h3>
            <p>{{ $t('home.verified_accounts_description') }}</p>
          </div>

          <div class="low-fees desktop-card" v-show="!isMobile">
            <div>
              <img src="@/assets/svg/v2/home/lowFees1.svg" alt="Low fees" />
              <img src="@/assets/svg/v2/home/lowFees2.svg" alt="Low fees" />
            </div>
            <h3>{{ $t('home.low_fees') }}</h3>
            <p>{{ $t('home.low_fees_description') }}</p>
          </div>

          <div class="verified-accounts desktop-card" v-show="!isMobile">
            <div>
              <img
                src="@/assets/svg/v2/home/verifiedAccount.svg"
                class="themed-img"
                alt="Verified account"
              />
            </div>
            <h3>{{ $t('home.verified_accounts') }}</h3>
            <p>{{ $t('home.verified_accounts_description') }}</p>
          </div>
        </div>
        <div class="second-guarantee-bloc">
          <div
            class="anonymous-transactions mobile-card"
            @click="handleCardClick"
            v-show="isMobile && currentIndex === 2"
          >
            <div>
              <img
                src="@/assets/svg/v2/home/anonymousTransactions.svg"
                alt="Anonymous transactions"
                height="38"
              />
            </div>
            <h3>{{ $t('home.anonymous_transactions') }}</h3>
            <p>{{ $t('home.anonymous_transactions_description') }}</p>
          </div>

          <div
            class="secure-transactions mobile-card"
            @click="handleCardClick"
            v-show="isMobile && currentIndex === 3"
          >
            <div>
              <img
                src="@/assets/svg/v2/home/secureTransactions.svg"
                alt="Secure transactions"
                height="38"
              />
            </div>
            <h3>{{ $t('home.secure_transactions') }}</h3>
            <p>{{ $t('home.secure_transactions_description') }}</p>
          </div>

          <div class="anonymous-transactions desktop-card" v-show="!isMobile">
            <div>
              <img
                src="@/assets/svg/v2/home/anonymousTransactions.svg"
                alt="Anonymous transactions"
                height="38"
              />
            </div>
            <h3>{{ $t('home.anonymous_transactions') }}</h3>
            <p>{{ $t('home.anonymous_transactions_description') }}</p>
          </div>

          <div class="secure-transactions desktop-card" v-show="!isMobile">
            <div>
              <img
                src="@/assets/svg/v2/home/secureTransactions.svg"
                alt="Secure transactions"
                height="38"
              />
            </div>
            <h3>{{ $t('home.secure_transactions') }}</h3>
            <p>{{ $t('home.secure_transactions_description') }}</p>
          </div>
        </div>
        <div class="carousel-dots" v-show="isMobile">
          <span
            v-for="(dot, index) in 4"
            :key="index"
            :class="['dot', { active: currentIndex === index }]"
            @click="setCurrentIndex(index)"
          >
          </span>
        </div>
      </div>
    </div>

    <AccountDetailsVerification :homePage="true" />

    <div class="subscriptions">
      <h2>{{ $t('home.title_4') }}</h2>

      <subscriptions :homePage="true"></subscriptions>
    </div>

    <div class="pre-footer">
      <h2>{{ $t('home.join_community') }}</h2>

      <div class="checked-items">
        <div class="secure-transactions">
          <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item"/>
          <p>{{ $t('home.secure_transactions') }}</p>
        </div>
        <div class="low-fees">
          <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item"/>
          <p>{{ $t('home.fees') }}</p>
        </div>
        <div class="verified-accounts">
          <img src="@/assets/svg/v2/home/checked.svg" alt="Checked item"/>
          <p>{{ $t('home.verified_accounts') }}</p>
        </div>
      </div>

      <button @click="redirect('/register')" class="generic-btn">
        {{ $t('home.create_account') }}
      </button>
    </div>
  </div>
</template>

<script>
import search from '@/components/modules/search_V2.vue'
import subscriptions from '../components/subscriptions.vue'
import AccountDetailsVerification from '@/components/accountCertifInformations.vue'
import subscribeNewsletter from '@/components/modules/newsletter/subscribeNewsletter.vue'

export default {
  name: 'Home',
  components: {
    search,
    subscriptions,
    AccountDetailsVerification,
    subscribeNewsletter,
  },
  data() {
    return {
      isLoading: false,
      currentIndex: 0,
      isMobile: window.innerWidth <= 725,
      interval: null,
      userInteractionDelay: null,
    }
  },
  mounted() {
    this.$store.commit('SET_POSITION', 'home')

    const video = document.getElementById('video-presentation-trading')
    video.addEventListener('loadedmetadata', function () {
      video.currentTime = 1
    })

    const videoBlocktrade = document.getElementById(
      'video-presentation-blocktrade'
    )
    videoBlocktrade.addEventListener('loadedmetadata', function () {
      videoBlocktrade.currentTime = 1
    })

    window.addEventListener('resize', this.checkMobile)
    if (this.isMobile) {
      this.startCarousel()
    }
  },
  watch: {
    returnLocale() {
      this.isLoading = true

      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    isLoading() {
      if (!this.isLoading) {
        this.$nextTick(() => {
          const video = document.getElementById('video-presentation-trading')
          video.addEventListener('loadedmetadata', function () {
            video.currentTime = 1
          })

          const videoBlocktrade = document.getElementById(
            'video-presentation-blocktrade'
          )
          videoBlocktrade.addEventListener('loadedmetadata', function () {
            videoBlocktrade.currentTime = 1
          })
        })
      }
    },
  },
  computed: {
    returnLocale() {
      // return this.$i18n.locale;
      return 'en-GB'
    },
  },
  methods: {
    redirect(path) {
      this.$router.push(path)
      window.scrollTo(0, 0)
    },

    nextCard() {
      this.currentIndex = (this.currentIndex + 1) % 4
    },
    setCurrentIndex(index) {
      this.currentIndex = index
      this.resetCarouselWithDelay()
    },
    handleCardClick() {
      this.resetCarouselWithDelay(8000)
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 725
      if (this.isMobile) {
        this.startCarousel()
      } else {
        this.stopCarousel()
      }
    },
    startCarousel() {
      if (!this.interval) {
        this.interval = setInterval(this.nextCard, 4000)
      }
    },
    stopCarousel() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    resetCarouselWithDelay() {
      this.stopCarousel()
      clearTimeout(this.userInteractionDelay)
      this.userInteractionDelay = setTimeout(() => {
        this.startCarousel()
      }, 8000)
    },
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobile)
    this.stopCarousel()
    clearTimeout(this.userInteractionDelay)
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.home,
.home-heading,
.buy-sell,
.storage,
.blocktrade,
.newsfeed,
.blog,
.events,
.portfolio-price-history,
.checked-items,
.checked-items div,
.guarantee {
  display: flex;
}

.el-backtop {
  background-color: $primary-color;
  color: var(--text-color);
  border-radius: 50%;
  box-shadow: var(--box-shadow-3);
  &:hover {
    background-color: lighten($primary-color, 10%);
  }
}

.home {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .home-heading {
    flex-direction: column-reverse;
    padding-top: 10px;
    h1 {
      font-family: Hyperwave;
      text-align: center;
      margin: 15px auto 20px;
      margin-bottom: 0px;
      font-weight: 400;
      font-size: 40px;
    }
  }

  .home-banner {
    margin: 30px auto;
    object-fit: cover;
  }

  .home-background {
    position: absolute;
    top: -110px;
    z-index: -1;
    width: 1200px;
    height: 1100px;
  }

  .checked-items {
    margin-top: 27px;
    margin-bottom: 30px;
    display: flex;

    div:nth-child(2) {
      margin: 0 25px;
    }

    div {
      img {
        margin-right: 5px;
      }

      p {
        color: var(--text-color);
        font-size: 17px;
      }
    }
  }

  .bloc-create-account {
    background: linear-gradient(
        to right,
        var(--background-color-10) 50%,
        var(--background-color-11) 100%
      ),
      url('../assets/img/background_2.webp') no-repeat center center / cover;
    position: relative;
    margin-bottom: 30px;
    box-sizing: border-box;
    padding: 15px;
    max-width: 1300px;
    border-radius: 12px;
    width: 95%;

    .bca-intro {
      font-family: Hyperwave;
      color: var(--text-color);
      font-size: 28px;
      font-weight: 400;
      width: 90%;
      margin-bottom: 20px;
      padding-left: 43px;
      text-align: center;
    }
    .bca-intro:nth-child(2) {
      margin-bottom: 30px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      cursor: pointer;
      font-size: 15px;
      border: 0;
      padding-left: 43px;
      img {
        margin-left: 5px;
      }
    }
  }

  .divider-guarantee {
    border-color: var(--border);
    margin: 0;
    width: 92%;
  }

  .guarantee {
    width: 100%;
    flex-direction: column;
    align-items: center;

    h2 {
      font-family: Hyperwave;
      color: var(--text-color);
      font-size: 40px;
      font-weight: 400;
      margin-top: 47px;
      margin-bottom: 54px;
      text-align: center;
    }

    .guarantee-bloc {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 50px;
      gap: 23px;
      margin-left: 23px;
      margin-right: 23px;

      .first-guarantee-bloc,
      .second-guarantee-bloc {
        display: flex;
        flex-wrap: wrap;
        gap: 23px;
        justify-content: center;
      }

      .secure-transactions,
      .low-fees,
      .anonymous-transactions,
      .verified-accounts {
        border-radius: 20px;
        box-shadow: var(--box-shadow-4);
        border: 1px solid var(--border);
        background: var(--background-color-2);
        color: var(--text-color);
        width: 293px;
        padding-left: 23px;
        padding-right: 10px;
        height: 300px;

        div {
          margin-top: 25px;
          margin-bottom: 23px;
        }

        h3 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 15px;
        }

        p {
          font-size: 15px;
          font-weight: 500;
        }
      }

      .low-fees {
        div {
          display: flex;
          justify-content: flex-start;

          img {
            margin-right: 5px;
          }
        }
      }

      .secure-transactions {
        background: linear-gradient(180deg,
        rgba(168, 85, 247, 0.5) 0%,
        rgba(168, 85, 245, 0) 100%);
      }

      .low-fees {
        background: linear-gradient(180deg,
        rgba(132, 204, 22, 0.5) 0%,
        rgba(132, 204, 22, 0) 100%);
      }

      .anonymous-transactions {
        background: linear-gradient(180deg,
        rgba(245, 159, 11, 0.5) 0%,
        rgba(245, 158, 11, 0) 100%);
      }

      .verified-accounts {
        background: linear-gradient(180deg,
        rgba(38, 103, 255, 0.5) 0%,
        rgba(38, 103, 255, 0) 100%);
      }
    }
  }

  .subscriptions {
    h2 {
      color: var(--text-color);
      font-family: Hyperwave;
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: 20px;
      text-align: center;
    }
  }
}

.spirits-types-list,
.spirits-types-list-1,
.spirits-types-list-2 {
  justify-content: left !important;
  margin: 0 !important;
  
  div {
    justify-content: left !important;

    div {
      justify-content: left !important;
    }
  }
}

.trading-without-subscriptions {
  margin-bottom: 30px;
  margin-top: 10px;

  h2 {
    font-family: Hyperwave;
    font-size: 34px;
    font-weight: 700;
    color: var(--text-color);
  }
}

.buy-sell,
.storage,
.blocktrade,
.newsfeed,
.blog,
.events,
.portfolio-price-history {
  a:hover {
    h3 {
      color: $primary-color;
      transition: color 300ms ease;
      img {
        filter: brightness(0) saturate(100%) invert(33%) sepia(100%)
          saturate(700%) hue-rotate(190deg);
        transition: filter 300ms ease;
      }
    }
    p {
      color: $primary-color;
      transition: color 300ms ease;
    }
  }
}

.buy-sell,
.storage,
.blocktrade,
.newsfeed,
.blog,
.events,
.portfolio-price-history {
  a {
    h3 {
      transition: color 300ms ease;
      img {
        transition: filter 300ms ease;
      }
    }
    p {
      transition: color 300ms ease;
    }
  }
}

.buy-sell,
.storage,
.blocktrade,
.newsfeed,
.blog,
.events {
  margin: 0 70px;
  max-width: 1400px;
  width: 100%;
  align-items: center;

  .title-size {
    display: none;
  }
  .hidden-desktop {
    display: none;
  }

  h3 {
    position: relative;
    .link-title {
      position: absolute;
      padding: 0;
      width: 25px !important;
      margin-left: 5px;
      bottom: 4px;
    }
  }

  video {
    max-width: 450px;
    margin-left: 25px;
  }

  video::-internal-media-controls-download-button {
    display: none;
  }
  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }
  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }

  img {
    padding: 15px;
  }

  div {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-family: Hyperwave;
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.blocktrade {
  flex-direction: row-reverse;
  margin-top: 5px;
  video {
    margin-right: 15px;
  }
}

.buy-sell,
.blocktrade {
  div {
    margin-left: 20px;
  }
  a {
    margin-left: 5px;
  }
}

.newsfeed,
.blog,
.events {
  margin-bottom: 50px;
  img {
    max-width: 450px;
  }
  a:hover {
    h3 {
      color: $primary-color;
      img {
        filter: brightness(0) saturate(100%) invert(33%) sepia(100%)
          saturate(700%) hue-rotate(190deg);
      }
    }
  }
}

.storage {
  margin: 20px 0;
  border: none;
}

.storage-img {
  max-height: 235px;
}


.newsfeed,
.storage,
.events {
  flex-direction: row-reverse;
  div {
    margin-right: 50px;
  }
}

.newsfeed {
  margin-top: 0px;
}
.blog {
  div {
    margin-left: 50px;
  }
}
.events {
  margin-bottom: 100px;
}

.portfolio-price-history {
  margin: 0 70px;
  margin-bottom: 70px;
  max-width: 1400px;
  color: var(--text-color);

  .portfolio,
  .price-history {
    width: 50%;

    div {
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: flex-start;
      height: 270px;
      padding: 5px;
      border-radius: 20px;
      // border: 1px solid var(--border);
      // box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
      margin-bottom: 24px;
      max-width: 650px;

      img {
        width: 95%;
        height: 100%;
        max-width: 540px;
      }
    }

    h3 {
      font-family: Hyperwave;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 13px;
      position: relative;
      .link-title {
        position: absolute;
        padding: 0;
        width: 25px !important;
        margin-left: 2px;
        bottom: 0px;
      }
    }
  }

  .price-history {
    margin-right: 25px;
  }
}

.pre-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 433px;
  background: no-repeat url('../assets/img/prefooter-background.webp');
  background-size: cover;

  h2 {
    font-family: Hyperwave;
    text-align: center;
    max-width: 622px;
    color: white;
    font-size: 30px;
    font-weight: 700;
    margin-top: 0px;
  }

  .checked-items {
    margin-top: 18px;
    margin-bottom: 34px;
    p {
      color: white !important;
    }
    
  }

  button {
    width: 230px;
  }
}

@media screen and (max-width: 1300px) {
  .buy-sell,
  .storage,
  .blocktrade,
  .newsfeed,
  .blog,
  .events {
    width: 100%;

    div {
      margin: auto;
    }
  }
}

@media screen and (max-width: 725px) {
  .guarantee {
    h2 {
      font-size: 30px !important;
      font-weight: 700 !important;
      margin: 30px 0 !important;
    }

    .guarantee-bloc {
      flex-direction: column;
      gap: 0 !important;
    }

    .mobile-card .active-mobile-card {
      display: block;
      margin: auto;
    }

    .carousel-dots {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    .dot {
      width: 18px;
      height: 10px;
      margin: 5px 5px;
      background-color: var(--background-color-4);
      border-radius: 3px;
      transition: background-color 0.3s, width 0.3s;
    }

    .dot.active {
      width: 36px;
      transition: background-color 0.3s, transform 0.3s;
    }

    .dot.active:nth-child(1) {
      background-color: #67c23a;
    }

    .dot.active:nth-child(2) {
      background-color: #2667ff;
    }

    .dot.active:nth-child(3) {
      background-color: #f59e0b;
    }

    .dot.active:nth-child(4) {
      background-color: #7529be;
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    .home-heading {
      flex-direction: column;
      padding: 0 15px;
      h1 {
        font-size: 30px;
        margin-top: 15px;
      }
    }
    .home-banner {
      width: 90%;
      margin: 0 auto 20px;
    }
  }

  .checked-items {
    flex-direction: column;
    margin-bottom: 33px !important;

    div {
      margin: 0 0 10px !important;
    }
  }

  .bloc-create-account {
    .bca-intro {
      font-size: 21px !important;
      margin: auto;
      margin-bottom: 0 !important;
      padding: 8px 0 !important;
    }

    .bca-intro:nth-child(2) {
      margin-bottom: 10px !important;
    }

    button {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 15px;
      width: 90%;
      border-radius: 10px;
      padding: 8px !important;

      img {
        margin-left: 5px;
      }
    }
  }

  .trading-without-subscriptions {
    h2 {
      text-align: center;
      font-size: 30px;
      padding: 0 15px;
    }
  }

  .buy-sell,
  .storage,
  .blocktrade,
  .newsfeed,
  .blog,
  .events {
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 0;

    .hidden-desktop {
      display: flex;
      a h3 {
        display: block;
        .link-title {
          bottom: 20px;
        }
      }
    }

    h3.hidden-desktop {
      margin: 0;
      padding: 0 15px;
    }

    .hidden-tel {
      display: none;
    }

    video {
      height: 100%;
      width: 320px;
      margin-left: 0;
    }

    img {
      width: 90%;
      object-fit: cover;
      padding: 5px;
    }

    div {
      padding: 0 20px;

      h3 {
        font-size: 26px;
        margin: auto;
        text-align: center;
      }

      p {
        font-size: 15px;
        margin-top: 20px;
      }

      img {
        max-width: 287px;
        padding: 5px;
      }
    }
  }

  .buy-sell,
  .blocktrade,
  .storage {
    .title-size {
      display: block;
      height: auto;
      margin: 20px 10px 30px;
      .link-title {
       bottom: 0;
       right: 10px;
       margin-left: 0;
      }
    }
  }

  .storage {
    flex-direction: column-reverse;
    margin-top: 15px;
    div {
      text-align: left;
      margin-bottom: 0;
    }
    .storage-img {
      padding: 25px 0 0;
      object-position: 20%;
    }
    .link-title {
      bottom: 0 !important;
    }
  }

  .newsfeed,
  .blog,
  .events {
    margin-top: 10px;
    .hidden-desktop {
      position: relative;
      left: 0;
      margin: 0;
      h3 {
        padding: 0 20px 20px 20px;
        text-align: left;
      }
    }
  }

  .portfolio-price-history {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-top: 20px;

    .portfolio,
    .price-history {
      display: grid;
      grid-template-areas: 'elem2' 'elem1' 'elem3';
      width: 90%;
      margin: auto;
      margin-bottom: 51px;

      .elem1 {
        grid-area: elem1;
        margin: 10px auto 20px;
      }

      div {
        width: 100%;
        max-height: 175px;
        max-width: 350px;
        margin-bottom: 20px;

        img {
          width: 99%;
        }
      }
    }

    .subscriptions {
      h2 {
        font-size: 30px !important;
        font-weight: 700 !important;
        max-width: 286px;
        margin: auto;
      }
    }

    .pre-footer {
      h2 {
        max-width: 348px;
        margin-top: 30px;
      }

      button {
        margin-bottom: 70px;
      }
    }

    .home-background {
      display: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .home {
    .home-heading {
      .revolution {
        margin: 25px auto;
      }
    }
  }
}
</style>
