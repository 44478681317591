export default {
  "commons": {
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy & Sell"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bid"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an error"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of account"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product origin"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue to"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search & Trading"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Night mode"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear mode"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matches"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account settings"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activated"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications for the moment"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot right-click"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checking the transaction"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You make too many requests"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached your daily consultation limit. Check your account to avoid any further restrictions."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your entry:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, try again later"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in again"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please validate your email and phone before performing this action"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorised to perform this action"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service unavailable, please contact customer service"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a language"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polish"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danish"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greek"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungarian"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japanese"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korean"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovak"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current orders"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse the match"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check that all required fields have been filled in correctly"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code sent"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note, the code is valid for 15 minutes"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insight"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your entry or contact customer service"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful copy"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The action has been cancelled as the waiting time has been exceeded. Please try again"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
    "spirit_type_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "storage_tep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP Storage"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must be over 18 to visit this site"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your age to enter."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am over 18"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am under 18"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By accessing this site, you agree to the"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions of use"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A revolution in itself"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reference for buying, selling and storing your spirits"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you're a whisky lover, a cognac collector or a professional in search of perfection, The Exchange Platform is for you. Optimum security, total transparency and low fees: discover spirits trading with complete peace of mind."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure transactions"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduced fee 2.5% VAT included"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% verified free accounts"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you are an individual or a professional, explore our aggregate of over 70 price sources on thousands of spirits references!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your free account without subscription"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying and selling: your transactions are secure and transparent"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Exchange Platform makes it easy to buy and sell continuously. Get easy access to all available offers thanks to our standardised references, which make comparison easy and guarantee transparency. Find what you're looking for or express your interest: TEP allows you to match your needs with counterparties' searches, whether you're buying or selling. Save time, optimise your transactions and always trade at the best price. With an intuitive interface and secure transactions, TEP simplifies spirits trading, offering a fast, reliable and efficient experience for amateurs and professionals alike."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottle exchange: secure and guaranteed with no counterparty risk"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP is the only platform that offers the possibility of exchanging bottles in complete security thanks to our collateralised cash or warehouse deposit solutions. This system guarantees total protection: there is no counterparty risk, because the money is secured with our payment services provider until the respective deliveries of the bottles are confirmed. This feature is revolutionising the spirits market by providing unprecedented transparency and reliability. Whether you're a collector or a connoisseur, Exchange allows you to expand or optimise your collection without any worries about transaction security. Exchange your spirits with complete peace of mind, in a modern and secure environment."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade: Secure your external transactions with ease"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade, an exclusive TEP feature, allows you to secure your buy/sell transactions in just a few clicks. Thanks to this innovation, a unique link is generated for each transaction, guaranteeing reliable and secure execution. Whether you're buying or selling, Block Trade eliminates the risks associated with direct external exchanges by providing a transparent and secure framework. It also incorporates advanced tools to confirm transaction terms, verify accounts and ensure smooth execution at every stage. Discover a new way to trade your spirits, with the peace of mind and reliability that TEP offers."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio management: a revolution for collectors and investors"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the more experienced, The Exchange Platform offers a unique portfolio management tool. This feature allows you to monitor your positions and their value in real time using live prices from the platform, combined with our detailed historical data. No more endless questions about the value of your collection! With an unprecedented objective and agnostic view, you can monitor the evolution of your assets and make informed decisions. Whether you're evaluating your collection or planning strategic transactions, TEP's portfolio management tool transforms your experience by providing total transparency and optimal control over your spirits."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical prices: unrivalled transparency"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At TEP, we have compiled the most comprehensive price history of any possible spirits, with data going back to 2002! Thanks to the aggregation of 70 different sources, supplemented by our own transactions, we offer a transparent and agnostic view of past prices realised. All this data is carefully standardised and made available in 18 different currencies, enabling our users to compare and analyse market trends on a global scale. This unique functionality is an indispensable tool for collectors, investors and professionals wishing to make informed decisions, whether buying, selling or simply evaluating their spirits portfolio."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A guarantee of confidence"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments are made via our payment service provider Mangopay to ensure smooth transactions, no risk of money disappearing you will never send it to the counterparty."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest fees on the market"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have the lowest fees on the market, 2.5% incl. VAT for buyer and seller, no listing or reserve fees. You only pay if there is an effective transaction."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-transactional anonymity"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you are an individual or a professional, our platform is the solution to all of today's problems thanks to its pre-transactional anonymity."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to registration control by our payment service provider prior to account validation, no phantom account, scammer or bot can enter the platform. Traceability and security are total."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An offer tailored to your needs"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join a community to find, manage and trade your spirits!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of spirits"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottle"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, name, EAN, keywords..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search within"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading available without subscription!"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you're an amateur, a collector or a professional, The Exchange Platform is made for you: optimum security, total transparency and low transaction costs, with 70 historical price sources covering thousands of bottle references."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover spirits trading with complete peace of mind."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed: your free spirits news hub"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep up to date with the latest industry news, new releases, major events, and market trends through TEP's Newsfeed. This feature allows you to follow everything that's happening in the world of spirits in real time, with information from the most relevant and recognised sites in the sector. The Newsfeed gives you a complete, agnostic view of the news, so you can keep abreast of market developments all in one place."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review: your space for analysis and discovery"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore free tasting content with TEP's Blog & Review. With reviews from industry experts and enthusiasts, the Blog & Review gives you access to unique perspectives and trusted recommendations. Whether you're looking to discover new bottles, understand market trends or expand your knowledge, this feed is designed to enrich your experience of the world of spirits."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events Browser: your guide to spirits events"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With TEP's free Events Browser, you'll never miss another important meeting in the world of spirits. Find out about exclusive trade fairs and events wherever you are. Thanks to a carefully updated selection, the Events Browser brings you the most relevant events in the sector, whether you're a collector, an investor or simply an enthusiast. Plan your outings and enjoy unique experiences around spirits."])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Entrepôt: Store your spirits with TEP"])},
    "storage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With our Warehouse solution in partnership with our specialist service provider, TEP offers you a secure and professional storage service for your bottles of spirits. Our dedicated facilities guarantee optimal storage conditions, whether to protect your collection, your investments or to facilitate future transactions."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by alphabetical order"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of spirits"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter events"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upcoming events"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upcoming events"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual subscriptions"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual rates"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly rates"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 month free on an annual payment"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special offer"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional offer"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Price History"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For users who want complete historical data with no time lag."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per month"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per year"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by type of spirit"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to all references"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical prices with no time lag"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced statistics"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to a spirits"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice of spirits :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wines"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complementary module"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For experienced collectors who want more data and a real management tool."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join a community of specialists to analyse, manage and trade your spirits!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced portfolio management"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this offer"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to basket"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basket"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly payment"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual payment"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-total"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next sampling"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to payment"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This subscription is already in your basket"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are already a subscriber"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your basket is empty"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to subscriptions"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method of payment"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions on The Exchange Platform are carried out via our payment service provider Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name on payment card"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry date (MM/YY)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security code"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalise your purchase"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment summary"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying commission"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global VAT"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber"])},
    "included_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT included"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The card verification code (on the back of the card, generally 3 digits"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cancellation has been taken into account"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation failed, please contact customer service"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to The Exchange Platform"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access a seamless, secure and standardised trading environment."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save my login"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email with instructions on how to reset your password."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email with instructions on how to reset your password."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification code"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentication"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code received by email or telephone"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must contain 7 characters with at least one capital letter."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all fields correctly"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid code"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street number"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions of use"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions of use"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and the"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de The Exchange Platform."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to our newsletter"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create my free account and continue"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete my registration"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a verified account"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full address"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional email"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of company"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company number"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT number"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My company"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC check"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access a seamless, secure and standardised trading environment."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your use"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account type cannot be changed once registration has been completed."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuals"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account for personal use"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified professional account"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all the fields below"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private account"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Account"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional category"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal form"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficial owners holding more than 25% ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide details of other beneficial owners holding more than 25%."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["born on"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reside in"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and nationality"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password must contain at least 8 characters, including a capital letter, a small letter, a number and a special character."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation of your email and phone number"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code received by email"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code received by phone"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An e-mail has been sent to you."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An SMS has been sent to you."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive the code?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting confirmation"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid code"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations, you already have access to many of the site's features free of charge!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to have your identity verified now to enjoy the full experience?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a verified account"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a free verified account"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification of your identity"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of document"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity card"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import a photo from your passport"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import a photo of both sides of your identity card"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a file"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear and readable file"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size max. 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JPG, JPEG or PNG format"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit for verification"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The average verification time is around 24 hours."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited functionality"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the registration process and access all the features."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents sent"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently being checked"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can search for bottles and see historical prices."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot yet access the transactional part or take out subscriptions."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent on"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis extract or equivalent document less than three months old"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy of articles of association"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport or identity card, both sides"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document sent:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents sent:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for refusal:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal identity"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate identity"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutes"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shareholder declaration"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address verification"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to payment"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid card number"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name and surname on invalid card"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid expiry date"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credit card code"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC fees for companies"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The documents have been sent successfully. Validation will take place within 24 hours."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaration of beneficial owners"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average verification time is just a few minutes"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take clear, sharp photos:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure that the identity card or passport is fully visible, without shadows or reflections, and that all details are legible."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send in a compatible format:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a JPEG, JPG or PNG format, with sufficient resolution for the information to be clearly visible."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the full document :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure that all the necessary pages are included separately."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must add 2 proofs of identity"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to add a receipt for the Passport"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your file name or maximum size of 7MB per document has been exceeded"])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your bank details to receive the money from your sales."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your details will be subject to validation by our customer service department. You will receive an email when your bank details have been validated."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, routing number..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions on The Exchange Platform are carried out via our payment service provider Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bank details have been saved"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bank details have already been sent"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations! Your account is now verified."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse bottles"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access my portfolio"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited active mode"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now access the full range of functions."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to receive messages from The Exchange Platform at the above telephone number. Data charges may apply, answer STOP to unsubscribe."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you refuse 2FA SMS messages, you will not be able to confirm your account. You can always unsubscribe after confirming your phone number via our SMS 2FA system."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthplace"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of birth"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET number"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan or type the barcode"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent searches"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product displayed"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["displayed products"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product found with this search"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products found with this search"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product found"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products found"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing bottle?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View all"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block trade"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bottles matching your search were found."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See search history"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account to unlock unlimited access"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for free"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login required for this feature"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing bottle"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the bottle"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If necessary, specify the details of the missing bottle"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found."])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can describe where the reasons for refusal"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current orders"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottler page"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use combinations of keywords."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The search is not case sensitive."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no need to specify \"years\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writing a generic spirits category (rum, whisky, cognac, etc.) will not produce any results."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check that you have at least one keyword in the search bar."])},
    "bottle_in_portolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This bottle is in your Portfolio"])},
    "alert_buy_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have activated a buy alert on this bottle"])},
    "alert_sell_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have activated a sales alert on this bottle"])}
  },
  "product_page": {
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to portfolio"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy/Sell"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottle information"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker composite"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of spirits"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottler"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottling year"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contents"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottle number"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bottles produced"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production method"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of the distillery"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No order for this bottle"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker composite"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search and add a bottle to the chart"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker or product name"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bottle you are looking for has not been found"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sources"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No image available for this reference"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cask number"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View historical prices"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live orders"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of bottles"])},
    "orders": {
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buys"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an order"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No orders at the moment."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy order"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase price"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule an end date and time?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a purchase order"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell order"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add photos"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule an end date and time?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a sell order"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order successfully created"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules to observe as a"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid flooding the order book unnecessarily"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit your orders to real transactions."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoid repeated refusals"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeated refusals of a match can be perceived as malicious or irresponsible. Make sure you only enter into transactions if you intend to complete them."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respect recovery times"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeated failure to collect parcels on time can lead to inconvenience for the seller and additional costs."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respect the integrity of the order book"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rigging or manipulating the order book, deactivating an interest after a match has been rejected, etc., are considered deceptive practices. Make sure your actions are transparent."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be prepared for the associated costs"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local import and customs taxes may apply. Make sure you are prepared to pay them when concluding a transaction."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide accurate information"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure the description of the bottle is accurate and truthful. This helps buyers make an informed decision. Provide a full front and back photo."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respect the details of the offer"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You commit to sell a bottle, honour this commitment. Any offer not honoured will have consequences for the seller."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantee the quality of the packaging and its contents"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeated deliveries of damaged parcels (deterioration, breakage, leakage) and incorrect contents have an impact on you. Make sure you pack your bottles carefully."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respect delivery times"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeated failure to ship on time may affect your access to the transaction area. Plan accordingly to avoid delays."])},
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create buy order"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a sell order"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Swap Taker order"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Swap Giver order"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Block Trade"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight unit"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit size"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been processed. You will be contacted soon"])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package reference:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can detail your problem here to make it easier to solve the ticket"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm receipt of parcel"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming receipt of the parcel, you are confirming that the product meets your expectations."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In transit"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litigation"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery details"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking number"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track my parcel"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See delivery details"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In delivery"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please accept or decline your proposals before creating a new order"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade link"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique link to share"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure transaction"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the delivery note"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In verification"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymous transaction (3 EUR incl. VAT)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must upload images of the bottle"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot put the same image more than once"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only images smaller than 7mb and with the .JPG, .JPEG and .png extension are accepted."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order cannot be valid for less than one hour"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No purchase orders for the moment."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No sell orders for the moment."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The buyer won't know your identity"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home collection"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your parcel is collected from your home by UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection date"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First pick-up time"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last pick-up time"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This order does not match your country preferences or those of the counterparty"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add images"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".JPG, .PNG and .JPEG only (7MB max per photo)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available in relay points"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must verify your identity to access the transaction section"])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check my identity"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create alert"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email when a sell order is created."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email when a buy order is created."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You receive an email when an order is created."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create an alert, go to the product page of your choice."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices (optional)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your alert has been created"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating your alert"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your price must be between 50 and 30000 EUR or 0 EUR"])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause this notification"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivate this alert"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the link to share your Blocktrade order"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your blocktrade is available for a maximum of 2 hours. To share this private sale order, copy the link by clicking on the button above."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Blocktrade link has been copied correctly"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your input"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per unit (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package dimensions"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a bottle"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry date"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot add the same reference twice"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum quantity authorised for a single sale"])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an additional parcel"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price list"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must add at least"])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" photos"])},
      "storage_subscription_information_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees are calculated on a pro rata basis up to the end of the month and include the following month. You will then be debited every 1st of the month for"])},
      "storage_enter_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage entry fee"])},
      "storage_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fresh storage"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price History"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a bottle"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphs are for information only. We accept no responsibility for any errors."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our historical data aggregates"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sales"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sale"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["different sources."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["source."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line chart"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candle chart"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chart options"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices are displayed with a delay of"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No historical price data available. If we have not yet included them, please contact support to have them added."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current view"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare with"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bottles selected"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This bottle is already on the chart"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the limit of 6 bottles for the chart"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Composite"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an error"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of error"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarks"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the problem more precisely here..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screenshots / Images"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 images max."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No bottle found for this reference."])},
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery details"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been taken into account"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release date"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release price"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matches"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match buyer"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match seller"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept and sell"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["View my ", _interpolate(_named("num_transac")), " current transactions"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show photos"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My buy order"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell order"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy order"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Counterparty"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My sell order"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matches to display."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account creation and features available to all"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverified account"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified account"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free and unlimited full access"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price History"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced visualisation"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15-month deferral"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 months deferred"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15-month deferred valuation"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12-month deferred valuation"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 unique tickers"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 unique tickers"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickers per day"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View active orders"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transactional access"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID verification free of charge for individuals and subject to a fee for professionals. KYC carried out by our payment service provider Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited full access"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID copied"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import a CSV or Excel file"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottle"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unselect all"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a bottle"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To guide you,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV and XLSX files only"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an import model"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average holding period"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to another folder"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rename folder"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name of the file is required"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum number of characters must not exceed 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Table columns"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options table"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fold all"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfold all"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show sold stocks"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottles"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickers"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net price"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross price"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage location"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to buy"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a file"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a bottle"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent folder name"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional columns"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column order"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My bottles"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase date"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add price options"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale date"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange date"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Bottles matching your search were found."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the parent folder"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File name"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change bottle"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottles held"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottles in your possession"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique references"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottles owned multiplied by the respective purchase price"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottles owned multiplied by market value"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, difference between market value and purchase price multiplied by quantity"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current performance"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current annual return"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average price of all bottles"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average length of time a buyer's holding remains in the portfolio"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bottles bought so far"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your bottles sold so far"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total expenditure to date"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sales to date"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accumulated gain from day one to date"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realised gain over the selected period"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return calculated from day one (only take into account bottles sold)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective annual yield (bottles sold)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique references"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial Value Position"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current performance"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annualised return"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical annual return"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical realised return"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average price"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottles purchased"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker unique"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuation"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latent gain"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottles sold"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total expenditure"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total realised gain"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realised gain (period)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical realised return"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sales"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy/sell date"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net price"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices excl."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices with fees"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices with VAT"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net price"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross purchase price"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross selling price"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage location"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference details"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this file?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel file"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips for your excel file"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columns displayed"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space used"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a bottle"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import a file"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current bottle"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current bottles"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current reference"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current references"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current dossier"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to a folder"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bottles in the folder"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["references in the dossier"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to a folder"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holdings (day)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to buy"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of sale"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity sold"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WAB price"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WASD Awards"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset class"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to product sheet"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last price"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatility"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselect all"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selected bottle(s)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete bottle"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The portfolio is currently only available on PC, but will soon be available on mobile."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio volatility"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatility of spirits"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillery volatility"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage volatility"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatility age"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio premium coming soon"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See details"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to product page"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File created"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file has been modified"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file has been deleted"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating the file"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bottle has been added"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bottle has been modified"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bottle has been moved"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File successfully imported"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original file :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be moving this folder and all the bottles in it."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your file has been moved"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to delete as there are bottles in this folder"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send an offer"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only .jpg, .jpeg and .png files of less than 3Mb are authorised."])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account settings"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription management"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My subscriptions"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method of payment"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice history"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying & selling"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety and 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add another delivery address"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery address"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete delivery address"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent to"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account holder"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent on"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change your RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentication"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive a code each time you log in and each time a change is made to your account"])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentication by email"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentication by SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest connections"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the history of the last 10 connections to your account on all devices."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive the newsletter"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of e-mail address"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current email address"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email address"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change of telephone number"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current telephone number"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New telephone number"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a delivery address"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email incorrect"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect telephone number"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have this email address"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have this telephone number"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current month's expenditure"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriber since"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not a subscriber"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual subscriptions"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next payment date"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed until"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore available historical price subscriptions"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subscription will expire on"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription concerned"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm cancellation"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume subscription"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue a subscription"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The next payment will be made on"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription concerned"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment will be made every"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at the price of"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirming the recovery"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Committed until"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Price History"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice history"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download all"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download invoice"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have an invoice"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery details"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track package"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated delivery"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° transaction"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code received by e-mail"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the code received by phone"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New e-mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New telephone"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code received by e-mail"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code received by phone"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice and delivery address"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debit card no."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added on"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires on"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change my bank card"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not yet registered a payment card."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set this address as default"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred delivery address"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your preferences have been successfully registered"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City of birth"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of birth"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a buy order"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a sell order"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a swap order"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a blocktrade order"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A buyer turned down the match"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale order awaiting validation"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorise receipt of SMS (account validation and/or 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication preferences"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS Relay Point"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying options"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales options"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying visibility"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales visibility"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries to exclude"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries to include"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy my buying preferences for sales"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your changes have been taken into account"])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify account information"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check your username"])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to update your information. Please contact support if the problem persists."])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This username is no longer available, please choose another one"])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social networks"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help centre"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruitment"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a bug"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of services"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal information"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & conditions"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie management"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company registered in France"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery charges"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order summary"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold part :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery charges"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales charges"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction details"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order summary"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping costs"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery charges"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction fees"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Including VAT at"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS delivery charges"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Method of payment"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS Insurance"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicile"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection point"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal points available"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save this address for future purchases"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction failed. Please try again"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transaction has been successfully completed"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment is being verified"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait a few seconds"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to basket"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from basket"])},
    "storage_exit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit fees"])},
    "storage_exit_payment_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This feature is coming soon. Please contact customer service to release your bottles."])},
    "inssurance_global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
    "inssurance_global_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance included"])},
    "insurrance_option_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insure my bottles in the event of damage"])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of account"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current orders"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify buy order"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify sell order"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Block Trade"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify Swap order"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show deleted orders"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I would like to receive a"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I own a"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in exchange for"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What I would like to exchange for"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order successfully modified"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order successfully created"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of orders :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title, price..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for refusal"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuse"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing litigation"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declare a dispute"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transactions carried out."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filters"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published on :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modified on :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter articles"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items found."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount available"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On hold"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total amount"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movements"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment made"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transactions to display."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming transfer"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outgoing transfer"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seller payment"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw to my bank account"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal of available funds"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm withdrawal"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transfer to your bank account has been successfully completed"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transfer to your account has failed. If the problem persists, please contact Customer Service"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My wallet (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount remaining after the transaction :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment received"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access my trading history"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer money directly to your wallet to avoid payment fees"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your funds will be available within 2 to 3 working days."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element successfully copied"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet provisioning"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment is by instant transfer by default"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount available for withdrawal :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account ending with :"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced filters"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 filters are required for searches without keywords"])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratings :"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The description can contain up to 500 characters"])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check that your rating is between 1 and 100 and that your description is less than 500 characters long."])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a review"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a review"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View or change rating"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change valuation"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete rating"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To create an review, go to a product page"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category, name, score..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The description is private. It will not be publicly available"])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["optional"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum number of valuations not reached to make an average."])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 5 to 50 characters (letters and numbers accepted)"])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no rating for this bottle yet."])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The starting point for all users."])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recognises regular contributors."])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicates advanced commitment and expertise."])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A real commitment."])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recognises a leadership role in the community."])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For very active users only."])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ultimate rank, for the most dedicated contributors."])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make my comment private"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valuation"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ratings"])}
  },
  "storage": {
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title, reference..."])},
    "invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download purchase invoice"])},
    "check_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View or modify the bottle"])},
    "store_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage date"])},
    "exit_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take out my bottles"])},
    "supplier_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage"])},
    "reference_u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reference"])},
    "reference_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["references"])}
  },
  "order_renew": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been successfully renewed"])}
  }
}