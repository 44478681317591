<template>
  <div class="before-kyc">
    <div class="before-kyc-content">
      <h2>{{ $t('register.before_kyc.title') }}</h2>
      <h4>{{ $t('register.before_kyc.subtitle') }}</h4>
    </div>

    <div v-if="getUserDetails" class="before-kyc-actions">
      <button v-if="getUserDetails.address.account_type === 1" @click="$emit('goToKYC')" class="generic-btn center">
        {{ $t('register.before_kyc.continue') }}
      </button>

      <button v-else @click="$emit('goToKYC')" class="generic-btn center">
        {{ $t('register.before_kyc.continue_free') }}
      </button>
    </div>

    <AccountDetailsVerification :isHome="false" />

    <button @click="$router.push('/search-products')" class="search-bottles">
      <img
        src="@/assets/svg/v2/registerProcess/search.svg"
        class="themed-img"
        alt="Search bottles"
      />
      <p>{{ $t('register.success.search') }}</p>
    </button>
  </div>
</template>

<script>
import AccountDetailsVerification from '@/components/accountCertifInformations.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    AccountDetailsVerification,
  },
  data() {
    return {}
  },
  mounted() {
    this.getClient();
  },
  computed: {
    ...mapGetters({
      getUserDetails: 'getUserDetails'
    })
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
    })
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.before-kyc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px auto;
  max-width: 950px;
  padding: 0px 10px;

  .before-kyc-content {
    margin-bottom: 10px;

    h2,
    h4 {
      color: var(--text-color);
      font-weight: 500;
      text-align: center;
    }

    h4 {
      margin-top: 15px;
    }
  }
}

.before-kyc-actions {
  margin-bottom: 10px;
  
  button {
    font-weight: 600;
    font-size: 15px;
  }
}

.search-bottles {
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--border);
  background: var(--background-color-2);
  padding: 5px 12px;
  margin-top: 15px;

  p {
    color: var(--subtitle-text-color);
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
  }
}

@media screen and (max-width: 500px) {
  .before-kyc-actions {
    button {
      width: 290px;
    }
  }
}
</style>
