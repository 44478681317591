<template>
  <div v-if="!getAuthStatus" class="login">
    <div class="login-welcome" v-if="!authForgot">
      <img src="@/assets/svg/v2/background/login.svg" alt="Login background" />
      <h1>{{ $t('login.welcome') }}</h1>
      <p>{{ $t('login.subtitle') }}</p>
    </div>

    <form
      @submit.prevent="checkAllInputs()"
      v-if="!authForgot && auth2FA === 0"
    >
      <div class="login-container">
        <label for="email">
          <h4>{{ $t('register.email') }}</h4>
          <el-input
            class="input"
            type="email"
            minlength="5"
            maxlength="75"
            :class="{ errorInput: regex.email }"
            focus
            :placeholder="$t('register.email')"
            clearable
            autocomplete="username"
            v-model="login.email"
            @input="
              (value) => {
                login.email = value.replaceAll(' ', '').toLowerCase()
              }
            "
          />
        </label>
        <label for="password">
          <h4>{{ $t('register.password') }}</h4>
          <el-input
            class="input"
            type="password"
            minlength="7"
            v-model="login.password"
            :class="{ errorInput: regex.password }"
            :placeholder="$t('register.password')"
            clearable
            show-password
            autocomplete="new-password"
          />
        </label>
      </div>
      <div class="continue">
        <div class="remember-me">
          <div>
            <el-checkbox
              v-model="remember_me"
              :label="$t('login.save_email')"
              text-color="#FFFFFF99"
            />
          </div>
        </div>
        <button class="login-connect">{{ $t('login.login') }}</button>
        <div class="forgot-password">
          <p @click="forgotPassword()">{{ $t('login.forgot_password') }}</p>
        </div>
      </div>
    </form>

    <form
      v-if="auth2FA === 1"
      @submit.prevent="check2FAInput()"
      class="double-fa"
    >
      <h3>{{ $t('login.doubleFA.title') }}</h3>
      <h5>{{ $t('login.doubleFA.code_reception') }}</h5>
      <div class="login-container">
        <label for="email_code">
          <el-input
            class="input"
            type="text"
            minlength="6"
            maxlength="6"
            v-model="twofa.email_code"
            :class="{ errorInput: twofa_regex.email_code }"
            focus
            placeholder="CODE"
            clearable
          />
        </label>
      </div>
      <div class="continue-2fa">
        <button class="login-connect">{{ $t('login.login') }}</button>
      </div>
    </form>

    <ForgotPassword v-if="authForgot === 1"></ForgotPassword>
    <ForgotPasswordCode v-if="authForgot === 2"></ForgotPasswordCode>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import ForgotPassword from '@/components/auth/forgotPassword.vue'
import ForgotPasswordCode from '@/components/auth/forgotPasswordCode.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ForgotPassword,
    ForgotPasswordCode,
  },
  data() {
    return {
      remember_me: false,

      login: {
        email: null,
        password: '',
      },

      regex: {
        email: null,
        password: null,
      },

      twofa: {
        email_code: null,
      },

      twofa_regex: {
        email_code: null,
      },

      password_regex: this.$t('login.password_regex'),
      wrong_values: this.$t('login.wrong_values'),
      wrong_code: this.$t('login.wrong_code'),
    }
  },
  created() {
    this.$store.commit('SET_POSITION', 'login')
    if (localStorage.remember_me != undefined) {
      this.login.email = JSON.parse(localStorage.remember_me).username
      this.checkEmail()
      this.remember_me = true
    }
  },
  watch: {
    remember_me() {
      !this.remember_me ? localStorage.removeItem('remember_me') : null
    },
    getAuthStatus() {
      if (this.getAuthStatus === true && this.$route.path === '/login') {        
        if (this.$route.redirectedFrom) {
          this.$router.push(this.$route.redirectedFrom.path);
        } else {
          this.$router.push('/search-products');
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      getAuthStatus: 'getIsAuth',
    }),
    authForgot() {
      return this.$store.state.auth.forgot_password
    },
    auth2FA() {
      return this.$store.state.auth.two_fa
    },
  },
  methods: {
    ...mapActions({
      setLang: 'setLang',
    }),
    checkAllInputs() {
      this.checkEmail()
      this.checkPassword()
      this.checkValues()
    },
    checkEmail() {
      if (!this.login.email || this.login.email.length <= 0) {
        return (this.regex.email = true)
      }
      if (this.login.email.includes(' ')) {
        this.login.email = this.login.email.replaceAll(' ', '')
      }
      const regex_email =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!regex_email.test(this.login.email.replaceAll(' ', ''))) {
        this.regex.email = true
      } else {
        this.regex.email = false
      }
    },
    checkPassword() {
      const regex_password =
        //eslint-disable-next-line
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,80}$/
      if (!regex_password.test(this.login.password)) {
        this.regex.password = true
      } else {
        this.regex.password = false
      }
    },
    checkValues() {
      let count_value = 0
      let its_ok = 0
      for (const value in this.regex) {
        count_value++
        if (this.regex[value] === true) {
          this.can_submit = false
        } else {
          its_ok++
        }
        if (count_value === 2 && its_ok === 2) {
          this.can_submit = true
          this.loginMe()
        } else if (count_value === 2 && its_ok < 2) {
          if (this.regex.password === true) {
            ElMessage({
              message: this.password_regex,
              type: 'warning',
              grouping: true,
            })
          } else {
            ElMessage({
              message: this.wrong_values,
              type: 'warning',
              grouping: true,
            })
          }
        }
      }
    },
    loginMe() {
      const payload = {
        login: this.login,
        remember_me: this.remember_me,
      }
      this.$store.dispatch('login', payload)
    },
    forgotPassword() {
      this.$store.commit('SET_FORGOT_PASSWORD', 1)
    },
    check2FAInput() {
      const regex_code = /^[0-9]{6}$/

      if (!regex_code.test(this.twofa.email_code)) {
        ElMessage({
          message: this.wrong_code,
          type: 'error',
          grouping: true,
        })

        this.twofa.email_code = null
      } else {
        this.$store.dispatch('sendCode2FA', this.twofa).then(() => {
          this.setLang({ code: localStorage.lang })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/auth.scss';

.login,
.login-welcome {
  display: flex;
}

form {
  flex-direction: column;
}

.login {
  background: var(--background-color);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20%;

  .login-welcome {
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 12%;

    img {
      position: absolute;
      bottom: -80px;
      left: 80px;
    }

    h1 {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 8px;
    }

    p {
      margin: auto;
      color: var(--subtitle-text-color);
      width: 492px;
      text-align: center;
      margin-bottom: 6px;
    }
  }
}

label {
  h4 {
    color: var(--text-color);
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 26px;
  }
}

.remember-me {
  margin-top: 16px;
  margin-bottom: 24px;
  color: var(--subtitle-text-color);

  div h5 {
    font-weight: 500;
  }
}

.login-connect {
  display: flex;
  width: 492px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 52px;
  background: $primary-color;
  color: $light-color;
}

.forgot-password {
  p {
    cursor: pointer;
    margin-top: 13px;
    color: var(--text-color);
    width: 100%;
    text-align: center;
  }
}

.double-fa {
  h3 {
    color: var(--text-color);
    font-size: 15px;
    font-weight: 500;
    margin-top: 30px;
  }

  h5 {
    margin-top: 5px;
    color: var(--text-color);
    margin-bottom: 15px;
  }

  .login-container {
    margin-bottom: 15px;
  }

  .continue-2fa {
    display: flex;
    align-content: center;
    justify-content: center;
  }
}

.input {
  border: 1px solid var(--border) !important;
  border-radius: 4px !important;
}

@media screen and (max-width: 720px) {
  h1,
  .login-welcome > p {
    width: 360px !important;
    text-align: center;
  }

  .login-welcome {
    img {
      position: absolute;
      bottom: -80px;
      left: 30px !important;
    }
  }

  .login-connect {
    width: 280px;
  }
}
</style>
