export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recusar a correspondência"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de expiração"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar e vender"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perguntar"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doador"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomador"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunicar um erro"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de conta"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encomendas"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encomendas actuais"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar para"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repor"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origem do produto"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar para"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligação"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminar sessão"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma conta"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisa e negociação"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinaturas"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo noturno"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo livre"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de transacções"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partidas"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de transacções"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definições da conta"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativado"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativado"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar tudo"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De momento, não há notificações"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível clicar com o botão direito do rato"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar a transação"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faz demasiados pedidos"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atingiu o seu limite diário de consultas. Verifique a sua conta para não ter mais restrições."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar a sua entrada:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro, tente novamente mais tarde"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sessão novamente"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, valide o seu e-mail e telefone antes de efetuar esta ação"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não está autorizado a realizar esta ação"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço indisponível, contacte o serviço de apoio ao cliente"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed de notícias"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique se todos os campos obrigatórios foram preenchidos corretamente"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher uma língua"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francês"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglês"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanhol"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TI"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polaco"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alemão"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checo"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dinamarquês"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grego"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["húngaro"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonês"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coreano"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Português"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REINO UNIDO"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russo"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eslováquia"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinês"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunicar um problema"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprador"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código enviado"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenção, o código é válido durante 15 minutos"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artigos"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profissional"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulares"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique a sua entrada ou contacte o serviço de apoio ao cliente"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cópia"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cópia bem sucedida"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertas"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ação foi cancelada porque o tempo de espera foi ultrapassado. Por favor, tente novamente"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armazenamento"])},
    "spirit_type_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "storage_tep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armazém TEP"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É necessário ter mais de 18 anos para visitar este sítio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, verifique a sua idade para entrar."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenho mais de 18 anos"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenho menos de 18 anos"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao aceder a este sítio, o utilizador concorda com os"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termos e condições de utilização"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidade."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma revolução em si mesma"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A referência para comprar, vender e armazenar as suas bebidas espirituosas"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer seja um amante de whisky, um colecionador de conhaque ou um profissional em busca da perfeição, The Exchange Platform é para si. Segurança óptima, transparência total e taxas reduzidas: descubra o comércio de bebidas espirituosas com toda a tranquilidade."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacções seguras"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa reduzida 2,5% IVA incluído"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contas gratuitas 100% verificadas"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer seja um particular ou um profissional, explore o nosso agregado de mais de 70 fontes de preços em milhares de referências de bebidas espirituosas!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie a sua conta gratuita sem subscrição"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra e venda: as suas transacções são seguras e transparentes"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A plataforma de troca facilita a compra e venda contínua. Aceda facilmente a todas as ofertas disponíveis graças às nossas referências normalizadas, que facilitam a comparação e garantem a transparência. Encontre o que procura ou manifeste o seu interesse: a TEP permite-lhe fazer corresponder as suas necessidades às procuras das contrapartes, quer se trate de uma compra ou de uma venda. Ganhe tempo, optimize as suas transacções e negoceie sempre ao melhor preço. Com uma interface intuitiva e transacções seguras, a TEP simplifica a negociação de bebidas espirituosas, oferecendo uma experiência rápida, fiável e eficiente tanto para amadores como para profissionais."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca de garrafas: segura e garantida, sem risco de contraparte"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A TEP é a única plataforma que oferece a possibilidade de trocar garrafas com toda a segurança, graças às nossas soluções de depósito em numerário ou em armazém com garantia. Este sistema garante uma proteção total: não existe qualquer risco de contraparte, uma vez que o dinheiro está seguro junto do nosso prestador de serviços de pagamento até à confirmação das respectivas entregas das garrafas. Esta caraterística revoluciona o mercado das bebidas espirituosas, proporcionando uma transparência e uma fiabilidade sem precedentes. Quer seja um colecionador ou um conhecedor, o Exchange permite-lhe aumentar ou otimizar a sua coleção sem se preocupar com a segurança da transação. Troque as suas bebidas espirituosas com toda a tranquilidade, num ambiente moderno e seguro."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade: Proteja as suas transacções externas com facilidade"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Block Trade, uma funcionalidade exclusiva da TEP, permite-lhe assegurar as suas transacções de compra/venda com apenas alguns cliques. Graças a esta inovação, é gerado um link único para cada transação, garantindo uma execução fiável e segura. Quer se trate de uma compra ou de uma venda, a Block Trade elimina os riscos associados às trocas diretas externas, proporcionando um quadro transparente e seguro. Também incorpora ferramentas avançadas para confirmar os termos e condições da transação, verificar as contas e garantir uma execução sem problemas em todas as fases. Descubra uma nova forma de negociar os seus espíritos, com a tranquilidade e a fiabilidade que a TEP oferece."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão de carteiras: uma revolução para coleccionadores e investidores"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para os mais experientes, The Exchange Platform oferece uma ferramenta única de gestão de carteiras. Esta funcionalidade permite-lhe monitorizar as suas posições e o seu valor em tempo real, utilizando preços ao vivo da plataforma, combinados com os nossos dados históricos detalhados. Acabaram-se as perguntas intermináveis sobre o valor da sua coleção! Com uma visão objetiva e agnóstica sem precedentes, pode acompanhar a evolução dos seus activos e tomar decisões informadas. Quer esteja a avaliar a sua coleção ou a planear transacções estratégicas, a ferramenta de gestão de carteiras da TEP transforma a sua experiência ao proporcionar uma transparência total e um controlo ótimo sobre os seus espíritos."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços históricos: transparência sem igual"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na TEP, compilámos o histórico de preços mais completo de todas as bebidas espirituosas possíveis, com dados que remontam a 2002! Graças à agregação de 70 fontes diferentes, complementadas pelas nossas próprias transacções, oferecemos uma visão transparente e agnóstica dos preços realizados no passado. Todos estes dados são cuidadosamente normalizados e disponibilizados em 18 moedas diferentes, permitindo aos nossos utilizadores comparar e analisar as tendências do mercado à escala mundial. Esta funcionalidade única é uma ferramenta indispensável para coleccionadores, investidores e profissionais que desejem tomar decisões informadas, quer seja na compra, venda ou simplesmente na avaliação da sua carteira de bebidas espirituosas."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma garantia de confiança"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os pagamentos são efectuados através do nosso prestador de serviços de pagamento Mangopay para garantir transacções sem problemas, sem risco de o dinheiro desaparecer, nunca o enviará à contraparte."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas mais baixas do mercado"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temos as taxas mais baixas do mercado, 2,5% incl. IVA para comprador e vendedor, sem taxas de listagem ou reserva. Só paga se houver uma transação efectiva."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonimato pré-transacional"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer se trate de um particular ou de um profissional, a nossa plataforma é a solução para todos os problemas actuais graças ao seu anonimato pré-transacional."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graças ao controlo do registo pelo nosso prestador de serviços de pagamento antes da validação da conta, nenhuma conta fantasma, scammer ou bot pode entrar na plataforma. A rastreabilidade e a segurança são totais."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma oferta adaptada às suas necessidades"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se a uma comunidade para encontrar, gerir e negociar as suas bebidas espirituosas!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bebidas espirituosas"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafa"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, nome, EAN, palavras-chave..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar no"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação disponível sem subscrição!"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer seja um amador, um colecionador ou um profissional, a plataforma de troca foi feita para si: segurança óptima, transparência total e baixos custos de transação, com 70 fontes de preços históricos que abrangem milhares de referências de garrafas."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra o comércio de bebidas espirituosas com toda a tranquilidade."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed: o seu centro de notícias sobre bebidas espirituosas"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenha-se atualizado com as últimas notícias do sector, novos lançamentos, eventos importantes e tendências do mercado através do Newsfeed da TEP. Esta funcionalidade permite-lhe seguir tudo o que se passa no mundo das bebidas espirituosas em tempo real, com informações dos sites mais relevantes e reconhecidos do sector. O Newsfeed dá-lhe uma visão completa e agnóstica das notícias, para que possa manter-se a par da evolução do mercado num só local."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review: o seu espaço de análise e descoberta"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore conteúdos de degustação gratuitos com o Blog & Review da TEP. Com análises de especialistas e entusiastas do sector, o Blogue e Análise dá-lhe acesso a perspectivas únicas e recomendações de confiança. Quer pretenda descobrir novas garrafas, compreender as tendências do mercado ou expandir os seus conhecimentos, este feed foi concebido para enriquecer a sua experiência no mundo das bebidas espirituosas."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegador de eventos: o seu guia de eventos de bebidas espirituosas"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com o navegador de eventos gratuito da TEP, nunca mais perderá uma reunião importante no mundo das bebidas espirituosas. Informe-se sobre feiras e eventos exclusivos onde quer que esteja. Graças a uma seleção cuidadosamente actualizada, o Navegador de Eventos traz-lhe os eventos mais relevantes do sector, quer seja um colecionador, um investidor ou simplesmente um entusiasta. Planeie as suas saídas e desfrute de experiências únicas em torno das bebidas espirituosas."])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Entrepôt: Armazene as suas bebidas espirituosas com a TEP"])},
    "storage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com a nossa solução Warehouse, em parceria com o nosso prestador de serviços especializado, a TEP oferece-lhe um serviço de armazenamento seguro e profissional para as suas garrafas de bebidas espirituosas. As nossas instalações dedicadas garantem condições de armazenamento óptimas, quer seja para proteger a sua coleção, os seus investimentos ou para facilitar futuras transacções."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogue e análise"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed de notícias"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir filtros"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por ordem alfabética"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datas"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bebidas espirituosas"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisa de..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar eventos"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de início"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de fim"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["próximos eventos"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["próximos eventos"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinaturas anuais"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas anuais"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços mensais"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mês grátis num pagamento anual"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta especial"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta profissional"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços históricos do prémio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para os utilizadores que pretendem dados históricos completos e sem desfasamento temporal."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por mês"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por ano"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por tipo de bebida espirituosa"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso a todas as referências"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços históricos sem desfasamento temporal"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatísticas avançadas"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscrever uma bolsa de bebidas espirituosas"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha de bebidas espirituosas :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinhos"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outros"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Módulo complementar"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para coleccionadores experientes que pretendem mais dados e uma verdadeira ferramenta de gestão."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se a uma comunidade de especialistas para analisar, gerir e negociar as suas bebidas espirituosas!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão avançada da carteira"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher esta oferta"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao cesto"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cesto"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento mensal"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento anual"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-total"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima amostragem"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceder ao pagamento"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta subscrição já está no seu cesto"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já é um subscritor"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu cesto está vazio"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar às subscrições"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de contacto"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faturação"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pagamento"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As transacções na plataforma de troca são efectuadas através do nosso fornecedor de serviços de pagamento Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de crédito"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do cartão"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome no cartão de pagamento"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de expiração (MM/YY)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de segurança"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar a compra"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em breve"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo do pagamento"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissão de compra"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA global"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscritor"])},
    "included_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA incluído"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código de verificação do cartão (no verso do cartão, geralmente com 3 dígitos"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A anulação foi tida em conta"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O cancelamento falhou, por favor contacte o serviço de apoio ao cliente"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo à plataforma de troca"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceder a um ambiente de negociação contínuo, seguro e normalizado."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar o meu login"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sessão"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu-se da sua palavra-passe?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reposição da palavra-passe"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receberá um e-mail com instruções sobre como redefinir a sua palavra-passe."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o seu e-mail"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reposição da palavra-passe"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receberá um e-mail com instruções sobre como redefinir a sua palavra-passe."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova palavra-passe"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar palavra-passe"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de verificação"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupla autenticação"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o código recebido por correio eletrónico ou telefone"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sua palavra-passe deve conter 7 caracteres com pelo menos uma letra maiúscula."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, preencha todos os campos corretamente"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduza um código válido"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma conta"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilidade"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome próprio"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidade"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da rua"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correio eletrónico"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palavra-passe"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar palavra-passe"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu aceito o"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termos e condições de utilização"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu aceito o"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termos e condições de utilização"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e o"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidade"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de The Exchange Platform."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscrever a nossa newsletter"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o seu e-mail"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar a minha conta gratuita e continuar"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar o meu registo"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obter uma conta verificada"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da empresa"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço completo"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correio eletrónico profissional"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de empresa"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da empresa"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de IVA"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minha empresa"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações gerais"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados bancários"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados bancários"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceder a um ambiente de negociação contínuo, seguro e normalizado."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione a sua utilização"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tipo de conta não pode ser alterado após a conclusão do registo."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulares"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profissional"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta para uso pessoal"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta profissional verificada"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações gerais"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, preencha todos os campos abaixo"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta privada"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta Corporate"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria profissional"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma jurídica"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os beneficiários efectivos que detenham mais de 25% ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forneça informações pormenorizadas sobre outros beneficiários efectivos com uma participação superior a 25%."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nasceu em"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residir em"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" e nacionalidade"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A palavra-passe deve conter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula, um número e um carácter especial."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação do seu e-mail e número de telefone"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o código recebido por correio eletrónico"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o código recebido por telefone"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foi-lhe enviado um e-mail."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foi-lhe enviado um SMS."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não recebeu o código?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar para"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmado"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A aguardar confirmação"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduza um código válido"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parabéns, já tem acesso a muitas das funcionalidades do sítio gratuitamente!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gostaria de ter a sua identidade verificada agora para desfrutar de toda a experiência?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obter uma conta verificada"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obter uma conta verificada gratuita"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação da sua identidade"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar o tipo de documento"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de identidade"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar uma fotografia do seu passaporte"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar uma fotografia de ambos os lados do seu bilhete de identidade"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar um ficheiro"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ficheiro claro e legível"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho máximo do ficheiro: 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato JPG, JPEG ou PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submeter para verificação"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tempo médio de verificação é de cerca de 24 horas."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcionalidade limitada"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclua o processo de registo e aceda a todas as funcionalidades."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos enviados"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualmente a ser verificada"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode pesquisar garrafas e ver os preços históricos."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não é possível aceder à parte transacional nem subscrever contratos."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado em"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrato Kbis ou documento equivalente com menos de três meses"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cópia dos estatutos da empresa"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte ou bilhete de identidade (ambos os lados)"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento enviado:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos enviados:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recusado"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo da recusa:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidade pessoal"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identidade da empresa"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatutos"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração dos accionistas"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificação do endereço"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceder ao pagamento"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cartão inválido"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome próprio e apelido no cartão inválido"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de expiração inválida"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de cartão de crédito inválido"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas KYC para empresas"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os documentos foram enviados com sucesso. A validação será efectuada no prazo de 24 horas."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaração de beneficiários efectivos"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O tempo médio de verificação é de apenas alguns minutos"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tire fotografias claras e nítidas:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se de que o bilhete de identidade ou passaporte é totalmente visível, sem sombras ou reflexos, e que todos os pormenores são legíveis."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar num formato compatível:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilize um formato JPEG, JPG ou PNG, com resolução suficiente para que a informação seja claramente visível."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver o documento completo :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se de que todas as páginas necessárias são incluídas separadamente."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É necessário adicionar 2 comprovativos de identidade"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É necessário adicionar um recibo para o Passaporte"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome do ficheiro ou o tamanho máximo de 7MB por documento foi excedido"])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados bancários"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza os seus dados bancários para receber o dinheiro das suas vendas."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os seus dados serão sujeitos a validação pelo nosso serviço de apoio ao cliente. Receberá um e-mail quando os seus dados bancários tiverem sido validados."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, número de encaminhamento..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, preencha"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As transacções na plataforma de troca são efectuadas através do nosso fornecedor de serviços de pagamento Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os seus dados bancários foram guardados"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os seus dados bancários já foram enviados"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parabéns! A sua conta foi verificada."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar garrafas"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceder à minha carteira"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo ativo ilimitado"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode agora aceder a toda a gama de funções."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceito receber mensagens da Plataforma de Intercâmbio através do número de telefone acima indicado. Podem ser aplicadas taxas de dados, responda STOP para cancelar a subscrição."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se recusar mensagens SMS 2FA, não poderá confirmar a sua conta. Pode sempre cancelar a subscrição depois de confirmar o seu número de telefone através do nosso sistema SMS 2FA."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de nascimento"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de nascimento"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número SIRET"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler ou escrever o código de barras"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisas recentes"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar tudo"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produto apresentado"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produtos expostos"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produto encontrado com esta pesquisa"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produtos encontrados com esta pesquisa"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produto encontrado"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produtos encontrados"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta uma garrafa?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar por"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver tudo"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vender"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomador"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doador"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação em bloco"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foram encontradas garrafas que correspondam à sua pesquisa."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver histórico de pesquisa"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma conta para desbloquear o acesso ilimitado"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registo gratuito"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É necessário iniciar sessão para esta funcionalidade"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafa em falta"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da garrafa"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se necessário, especificar os pormenores da garrafa em falta"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode descrever os motivos da recusa"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcional"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foram encontrados resultados."])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encomendas actuais"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página do engarrafador"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É possível utilizar combinações de palavras-chave."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pesquisa não é sensível a maiúsculas e minúsculas."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é necessário especificar \"anos\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escrever uma categoria genérica de bebidas espirituosas (rum, whisky, conhaque, etc.) não produzirá quaisquer resultados."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique se tem pelo menos uma palavra-chave na barra de pesquisa."])},
    "bottle_in_portolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta garrafa está na sua carteira"])},
    "alert_buy_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activou um alerta de compra para esta garrafa"])},
    "alert_sell_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activou um alerta de venda para esta garrafa"])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trocador"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca de moeda"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de conta"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encomendas actuais"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direção"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar ordem de compra"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar ordem de venda"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar negociação em bloco"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar ordem Swap"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar ordens eliminadas"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gostaria de receber uma"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu possuo um"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["em troca de"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que eu gostaria de trocar por"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem modificada com sucesso"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem criada com sucesso"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante total :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de encomendas :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título, preço..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo da recusa"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recusa"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foi encontrada nenhuma garrafa para esta referência."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar à carteira"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar/Vender"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troca"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação em bloco"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações sobre a garrafa"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composição do ticker"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bebidas espirituosas"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destilaria"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engarrafador"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idade"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marca"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano de engarrafamento"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteúdo"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número da garrafa"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de garrafas produzidas"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de produção"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situação da destilaria"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificação"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há encomenda para esta garrafa"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composição do ticker"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ativo"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inativo"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar e adicionar uma garrafa à tabela"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker ou nome do produto"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A garrafa que procura não foi encontrada"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fontes"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não existe imagem disponível para esta referência"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número do barril"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver o histórico de preços"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordens em direto"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de garrafas"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar ordem de compra"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem de venda"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem Swap Taker"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem Swap Giver"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma transação em bloco"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidade de peso"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamanho da unidade"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulares"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mistura"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De momento, não há encomendas."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem de compra"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço de compra"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programar uma data e hora de fim?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem de compra"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem de venda"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duração"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largura"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar fotografias"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programar uma data e hora de fim?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem de venda"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem criada com sucesso"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu pedido foi processado. Será contactado em breve"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regras a observar enquanto"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evitar inundar desnecessariamente o livro de ordens"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite as suas ordens a transacções reais."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evitar recusas repetidas"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As recusas repetidas de uma correspondência podem ser vistas como maliciosas ou irresponsáveis. Certifique-se de que só efectua transacções se tiver intenção de as concluir."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respeitar os tempos de recuperação"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O incumprimento repetido do prazo de recolha das encomendas pode causar incómodos ao vendedor e custos adicionais."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respeitar a integridade do livro de encomendas"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A manipulação ou manipulação do livro de ordens, a desativação de um interesse após a rejeição de uma correspondência, etc., são consideradas práticas enganosas. Certifique-se de que as suas acções são transparentes."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esteja preparado para os custos associados"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podem aplicar-se impostos locais de importação e aduaneiros. Certifique-se de que está preparado para os pagar quando concluir uma transação."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecer informações exactas"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifique-se de que a descrição da garrafa é exacta e verdadeira. Isto ajuda os compradores a tomar uma decisão informada. Forneça uma fotografia completa da frente e do verso."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respeitar os pormenores da oferta"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se se comprometer a vender uma garrafa, honre esse compromisso. Qualquer oferta não honrada terá consequências para o vendedor."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantir a qualidade da embalagem e do seu conteúdo"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As entregas repetidas de encomendas danificadas (deterioração, rutura, fuga) e de conteúdo incorreto têm um impacto sobre si. Assegure-se de que embala as suas garrafas com cuidado."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respeitar os prazos de entrega"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O incumprimento repetido do prazo de envio pode afetar o seu acesso à área de transação. Planeie em conformidade para evitar atrasos."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência do pacote:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pode detalhar o seu problema aqui para facilitar a resolução do pedido"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar a receção da encomenda"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao confirmar a receção da encomenda, está a confirmar que o produto corresponde às suas expectativas."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponder"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À espera"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transporte marítimo"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar para"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em trânsito"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregue"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contencioso"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes de entrega"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de controlo"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir a minha encomenda"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver pormenores de entrega"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na entrega"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceite ou recuse as suas propostas antes de criar uma nova ordem"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligação Blocktrade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligação única para partilhar"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação segura com o nosso fornecedor de pagamentos Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar a nota de entrega"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em verificação"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transação anónima (3 EUR incl. IVA)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É necessário carregar imagens da garrafa"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível colocar a mesma imagem mais do que uma vez"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Só são aceites imagens com menos de 7mb e com a extensão .JPG, .JPEG e .png."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sua encomenda não pode ser válida por menos de uma hora"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De momento, não há ordens de compra."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De momento, não há ordens de venda."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O comprador não conhecerá a sua identidade"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleção inicial"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sua encomenda é recolhida em sua casa pela UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de recolha"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira hora de recolha"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última hora de recolha"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta ordem não corresponde às suas preferências de país ou às da contraparte"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar imagens"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou arrastar e largar"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas .JPG, .PNG e .JPEG (máximo de 7 MB por fotografia)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponível em pontos de transmissão"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É necessário verificar a sua identidade para aceder à secção de transacções"])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar a minha identidade"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar alerta"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receberá um e-mail quando for criada uma ordem de venda."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receberá um e-mail quando for criada uma ordem de compra."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O utilizador recebe um e-mail quando é criada uma ordem."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para criar um alerta, aceda à página do produto da sua escolha."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços (opcional)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu alerta foi criado"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao criar o seu alerta"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu preço deve situar-se entre 50 e 30000 EUR ou 0 EUR"])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar esta notificação"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reativar este alerta"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desativado"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie o link para partilhar a sua ordem Blocktrade"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu blocktrade está disponível por um período máximo de 2 horas. Para partilhar esta ordem de venda privada, copie o link clicando no botão acima."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ligação Blocktrade foi copiada corretamente"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, verifique a sua entrada"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço por unidade (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensões do pacote"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar uma garrafa"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de expiração"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível adicionar a mesma referência duas vezes"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atingiu a quantidade máxima autorizada para uma única venda"])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar uma parcela adicional"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de preços"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É necessário adicionar pelo menos"])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" fotos"])},
      "storage_subscription_information_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As taxas são calculadas numa base pro rata até ao final do mês e incluem o mês seguinte. O débito será efectuado todos os dias 1 de cada mês por"])},
      "storage_enter_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de entrada no armazém"])},
      "storage_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armazém de frescos"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços históricos"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar uma garrafa"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os gráficos têm um carácter meramente informativo. Não aceitamos qualquer responsabilidade por eventuais erros."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os nossos dados históricos agregam"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vendas"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["venda"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diferentes fontes."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fonte."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moeda"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Média"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topo"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de linhas"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico de velas"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções de gráficos"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desempenho"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os preços são apresentados com um atraso de"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mês"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não existem dados históricos de preços disponíveis. Se ainda não os tivermos incluído, contacte o serviço de apoio para que sejam adicionados."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista atual"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diário"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensal"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anual"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semanal"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparar com"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma garrafa selecionada"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta garrafa já está no gráfico"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atingiu o limite de 6 garrafas para o gráfico"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Composto"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunicar um erro"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de erro"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observações"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcional"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descreva o problema de forma mais precisa aqui..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capturas de ecrã / Imagens"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 imagens no máximo."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes de entrega"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu pedido foi tido em conta"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de lançamento"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços de saída"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partidas"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinar comprador"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor de correspondência"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar e vender"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ver as minhas transacções actuais ", _interpolate(_named("num_transac"))])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar fotos"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minha ordem de compra"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem de venda"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprador"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em curso"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem de compra"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraparte"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minha ordem de venda"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há correspondências a apresentar."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação de conta e funcionalidades disponíveis para todos"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta não auditada"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta verificada"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed de notícias"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso total gratuito e ilimitado"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços históricos"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização avançada"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferimento de 15 meses"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 meses de diferimento"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação diferida por 15 meses"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação diferida por 12 meses"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickers únicos"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 tickers únicos"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de dados"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickers por dia"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociação"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver ordens activas"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem acesso transacional"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verificação de identidade é gratuita para particulares e sujeita a uma taxa para profissionais. KYC efectuado pelo nosso fornecedor de serviços de pagamento Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acesso ilimitado e completo"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID copiado"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ficheiro"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar um ficheiro CSV ou Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafa"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar tudo"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desmarcar tudo"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar uma garrafa"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para o orientar,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregar um ficheiro"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou arrastar e largar"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas ficheiros CSV e XLSX"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um modelo de importação"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período médio de detenção"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renomear"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para outra pasta"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar o nome da pasta"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome do ficheiro é obrigatório"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número máximo de caracteres não deve exceder 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importador"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatísticas"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas da tabela"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabela de opções"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrar tudo"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desvendar tudo"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar acções vendidas"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafas"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickers"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço líquido"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço bruto"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comissões"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de armazenamento"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detenção"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexos"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onde comprar"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um ficheiro"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar uma garrafa"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome da pasta principal"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas adicionais"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem de coluna"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As minhas garrafas"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de compra"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar opções de preços"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de venda"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da bolsa"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venda"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não foram encontradas garrafas que correspondam à sua pesquisa."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar a pasta principal"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do ficheiro"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma conta"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudar de garrafa"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atual"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafas detidas"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafas na sua posse"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referências únicas"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafas detidas multiplicadas pelo respetivo preço de compra"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafas detidas multiplicadas pelo valor de mercado"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, diferença entre o valor de mercado e o preço de compra multiplicado pela quantidade"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desempenho atual"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento anual atual"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço médio de todas as garrafas"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo médio de permanência da participação de um comprador na carteira"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As suas garrafas compradas até agora"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As suas garrafas vendidas até agora"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas totais até à data"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de vendas até à data"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganhos acumulados desde o primeiro dia até à data"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganho realizado durante o período selecionado"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno calculado desde o primeiro dia (apenas tem em conta as garrafas vendidas)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento anual efetivo (garrafas vendidas)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referências únicas"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posição de valor inicial"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desempenho atual"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento anualizado"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento anual histórico"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico do retorno realizado"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço médio"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafas compradas"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker único"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliação"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganho latente"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafas vendidas"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas totais"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganho total realizado"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganho realizado (período)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico do retorno realizado"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de vendas"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de compra/venda"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço líquido"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços sem IVA."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços com taxas"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços com IVA"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço líquido"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço bruto de compra"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço bruto de venda"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de armazenamento"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da referência"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem a certeza de que pretende apagar este ficheiro?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ficheiro Excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugestões para o seu ficheiro Excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colunas apresentadas"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecionar tudo"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaço utilizado"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma conta"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar uma garrafa"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importar um ficheiro"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafa atual"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garrafas actuais"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência atual"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referências actuais"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossiê atual"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para uma pasta"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["garrafas na pasta"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referências no dossier"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para uma pasta"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participações (dia)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anexos"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onde comprar"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local de venda"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade vendida"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prémios WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe de activos"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para a ficha de produto"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último preço"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidade"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desmarcar tudo"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["garrafa(s) selecionada(s)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apagar garrafa"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualmente, a carteira só está disponível no PC, mas em breve estará disponível no telemóvel."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidade da carteira"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidade das bebidas espirituosas"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidade das destilarias"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilidade da colheita"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idade da volatilidade"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prémio da carteira em breve"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver pormenores"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para a página do produto"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ficheiro criado"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O ficheiro foi modificado"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O ficheiro foi eliminado"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro ao criar o ficheiro"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A garrafa foi adicionada"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A garrafa foi modificada"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A garrafa foi movida"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ficheiro importado com sucesso"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ficheiro original :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irá mover esta pasta e todas as garrafas nela contidas."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu ficheiro foi movido"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossível de apagar porque há garrafas nesta pasta"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar uma oferta"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Só são autorizados ficheiros .jpg, .jpeg e .png com menos de 3Mb."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da transação"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo das encomendas"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custos de envio"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposto"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vender"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recusa"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de entrega"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de transação"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante total"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluindo IVA em"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de entrega UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pagamento"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pagamento"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro UPS"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicílio"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponto de venda"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pontos de levantamento disponíveis"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar este endereço para futuras compras"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A transação falhou. Por favor, tente novamente"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A transação foi concluída com êxito"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O seu pagamento está a ser verificado"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguarde alguns segundos"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao cesto"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover do cesto"])},
    "storage_exit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de saída"])},
    "storage_exit_payment_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta funcionalidade está disponível em breve. Contacte o serviço de apoio ao cliente para libertar as suas garrafas."])},
    "inssurance_global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguros"])},
    "inssurance_global_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro incluído"])},
    "insurrance_option_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer um seguro das minhas garrafas em caso de danos"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definições da conta"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão de assinaturas"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As minhas subscrições"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pagamento"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de facturas"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minha conta"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compra e venda"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados bancários"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança e 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A empresa"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilidade"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nacionalidade"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome próprio"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de nascimento"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correio eletrónico"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Região"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar outro endereço de entrega"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de entrega"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar o endereço de entrega"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de faturação"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado para"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem informações"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titular da conta"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado em"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar o seu RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupla autenticação"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber um código sempre que iniciar sessão e sempre que for efectuada uma alteração na sua conta"])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupla autenticação por correio eletrónico"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupla autenticação por SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligações mais recentes"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja o histórico das últimas 10 ligações à sua conta em todos os dispositivos."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber a newsletter"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartão de débito n."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionado em"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expira em"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar o meu cartão bancário"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não registou um cartão de pagamento."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definir este endereço como predefinido"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de entrega preferencial"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração do endereço de correio eletrónico"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de correio eletrónico atual"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo endereço de correio eletrónico"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alteração do número de telefone"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone atual"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo número de telefone"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um endereço de entrega"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registar"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail incorreto"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de telefone incorreto"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já tem este endereço de correio eletrónico"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já tem este número de telefone"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despesas do mês atual"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinante desde"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é um subscritor"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinaturas anuais"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima data de recolha"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscrito até"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar a subscrição"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar as subscrições de preços históricos disponíveis"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar a subscrição"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sua subscrição expira em"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura em causa"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar o cancelamento"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscrição de currículos"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar uma subscrição"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O próximo pagamento será efectuado em"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura em causa"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O pagamento será efectuado a cada"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ao preço de"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmação da recuperação"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compromisso até"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mês"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços históricos do prémio"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico de facturas"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar tudo"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assinatura"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar fatura"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tem uma fatura"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes de entrega"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acompanhar o pacote"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimativa de entrega"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N.º de transação"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o código recebido por correio eletrónico"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduza o código recebido por telefone"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo correio eletrónico"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo telefone"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzir o código recebido por correio eletrónico"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzir o código recebido por telefone"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatura e endereço de entrega"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As suas preferências foram registadas com sucesso"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade de nascimento"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de nascimento"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem de compra"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem de venda"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem de troca"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma ordem blocktrade"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um comprador recusou o negócio"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordem de venda a aguardar validação"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizar a receção de SMS (validação da conta e/ou 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferências de comunicação"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponto de retransmissão UPS"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções de compra"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções de venda"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidade de compra"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidade das vendas"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países a excluir"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países a incluir"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar as minhas preferências de compra para venda"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As suas alterações foram tidas em conta"])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar informações da conta"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique o seu nome de utilizador"])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não é possível atualizar as suas informações. Contacte o apoio ao cliente se o problema persistir."])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este nome de utilizador já não está disponível, por favor escolha outro"])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redes sociais"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apoio"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de ajuda"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutamento"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunicar um erro"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado dos serviços"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações legais"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERMOS E CONDIÇÕES"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestão de bolachas"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empresa registada em França"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidade"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de entrega"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostos"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recusa"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo das encomendas"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte vendida :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de entrega"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de venda"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante total"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contencioso em curso"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declarar um litígio"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não são efectuadas transacções."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redefinir filtros"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicado em :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificado em :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar artigos"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum item encontrado."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante disponível"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em espera"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante total"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimentos"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento efectuado"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há transacções a apresentar."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência de entrada"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência de saída"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento do vendedor"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levantamento para a minha conta bancária"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levantamento dos fundos disponíveis"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar a retirada"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A transferência para a sua conta bancária foi concluída com êxito"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A transferência para a sua conta falhou. Se o problema persistir, contacte o Serviço de Apoio ao Cliente"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A minha carteira (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante restante após a transação :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento recebido"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceder ao meu histórico de negociação"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfira dinheiro diretamente para a sua carteira para evitar taxas de pagamento"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietário :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os seus fundos estarão disponíveis no prazo de 2 a 3 dias úteis."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento copiado com sucesso"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cópia"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecimento de carteiras"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por defeito, o pagamento é efectuado por transferência instantânea"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante disponível para levantamento :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta bancária terminada em :"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros avançados"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["São necessários 2 filtros para pesquisas sem palavras-chave"])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avaliações"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificações :"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A descrição pode conter até 500 caracteres"])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique se a sua classificação está entre 1 e 100 e se a sua descrição tem menos de 500 caracteres."])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma avaliação"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma avaliação"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver ou alterar a classificação"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar a avaliação"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar classificação"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para criar uma avaliação, aceda à página de um produto"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria, nome, pontuação..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A descrição é privada. Não estará disponível ao público"])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opcional"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número mínimo de avaliações não atingidas para se obter uma média."])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De 5 a 50 caracteres (são aceites letras e números)"])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não existe uma classificação para esta garrafa."])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O ponto de partida para todos os utilizadores."])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconhece os contribuidores regulares."])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica um compromisso e conhecimentos avançados."])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É um verdadeiro compromisso."])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconhece um papel de liderança na comunidade."])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apenas para utilizadores muito activos."])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A classificação máxima, para os contribuidores mais dedicados."])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apelido"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar o meu comentário privado"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avaliação"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["classificações"])}
  },
  "storage": {
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armazenamento"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título, referência..."])},
    "invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar a fatura de compra"])},
    "check_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver ou modificar a garrafa"])},
    "store_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de armazenamento"])},
    "exit_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tirar as minhas garrafas"])},
    "supplier_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armazém"])},
    "reference_u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referência"])},
    "reference_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referências"])}
  },
  "order_renew": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A sua encomenda foi renovada com sucesso"])}
  }
}