<template>
  <footer id="footer">
    <div class="footer-top">
      <div @click="$router.push('/')" class="bloc-logo">
        <img
          width="205"
          src="@/assets/img/tep.webp"
          alt="The exchange platform - Logo"
        />
      </div>

      <subscribeNewsletter />

      <div class="footer-links">
        <ul class="footer-social-medias">
          <h4>{{ $t('footer.social_medias') }}</h4>
          <li>
            <a
              href="https://www.facebook.com/TheExchangePlatform/"
              target="_blank"
            >
              <img
                width="20"
                height="20"
                src="@/assets/svg/v2/socials/facebook.svg"
                class="themed-img"
                alt="Facebook"
              />
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/thexchangeplatform/"
              target="_blank"
            >
              <img
                width="20"
                height="20"
                src="@/assets/svg/v2/socials/instagram.svg"
                alt="Instagram"
                class="themed-img"
              />
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/the-exchange-platform/"
              target="_blank"
            >
              <img
                width="20"
                height="20"
                src="@/assets/svg/v2/socials/linkedin.svg"
                alt="Linkedin"
                class="themed-img"
              />
              Linkedin
            </a>
          </li>
          <li>
            <a href="https://twitter.com/TheExchgPlatfrm" target="_blank">
              <img
                width="20"
                height="20"
                src="@/assets/svg/v2/socials/twitter.svg"
                class="themed-img"
                alt="Twitter - X"
              />
              Twitter - X
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@thexchangeplatform"
              target="_blank"
            >
              <img
                width="20"
                height="20"
                src="@/assets/svg/v2/socials/tiktok.svg"
                class="themed-img"
                alt="TikTok"
              />
              TikTok
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UC29vm69zH25TcRp-S_dCBvw"
              target="_blank"
            >
              <img
                width="20"
                height="20"
                src="@/assets/svg/v2/socials/youtube.svg"
                class="themed-img"
                alt="Youtube"
              />
              Youtube
            </a>
          </li>
        </ul>
        <ul>
          <h4>{{ $t('footer.support') }}</h4>
          <li>
            <a href="mailto:clientsupport@thexchangeplatform.io">{{
              $t('footer.help_center')
            }}</a>
          </li>
          <li>
            <a href="mailto:clientsupport@thexchangeplatform.io">{{
              $t('footer.faq')
            }}</a>
          </li>
          <li>
            <a href="mailto:clientsupport@thexchangeplatform.io">{{
              $t('footer.bug_report')
            }}</a>
          </li>
          <li class="external-links">
            <a href="https://mangopay.com/" target="_blank">
              <img
                class="mango-white themed-img"
                height="24"
                src="@/assets/svg/mangopayGrey.svg"
                alt="Mangopay"
              />
            </a>
            <a href="https://www.ups.com/" class="ups-link" target="_blank">
              <img
                width="24"
                height="24"
                src="@/assets/svg/ups.svg"
                alt="UPS"
              />
            </a>
          </li>
        </ul>
        <ul>
          <h4>{{ $t('footer.legals') }}</h4>
          <li @click="$router.push('/legal/tep')">
            {{ $t('footer.cgu_cgv') }}
          </li>
          <li @click="resetCookiesConsent()">{{ $t('footer.cookies') }}</li>
          <li @click="$router.push('/legal/privacy')">{{ $t('footer.privacy') }} </li>
        </ul>
      </div>
    </div>

    <div class="footer-bottom">
      <p>
        The Exchange Platform {{ currentYear }}, {{ $t('footer.tep_footer') }}
      </p>
    </div>
  </footer>
</template>

<script>
import m from 'moment'
import c from 'js-cookie'
import subscribeNewsletter from './modules/newsletter/subscribeNewsletter.vue'

export default {
  components: {
    subscribeNewsletter,
  },
  data() {
    return {}
  },
  computed: {
    currentYear() {
      return m().format('YYYY')
    },
  },
  methods: {
    resetCookiesConsent() {
      c.remove('CookieConsent')
      window.location.reload()
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

@media screen and (max-width: 850px) {
  .bloc-logo {
    display: none;
  }

  .footer-links {
    width: 100%;
    gap: 30px;
    flex-wrap: nowrap;
    ul {
      margin-right: 0px !important;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

.footer-top,
.footer-links,
.footer-social-medias li {
  display: flex;
}

footer {
  border-top: 1px solid var(--border-4);
  background-color: var(--background-color);
  padding-left: 66px;
  padding-right: 66px;

  .footer-top {
    justify-content: space-between;
    padding-top: 44px;

    .bloc-logo {
      img {
        cursor: pointer;
      }
    }

    .footer-links {
      flex-wrap: wrap;

      .footer-social-medias li {
        align-items: center;

        img {
          margin-right: 8px;
        }
      }

      ul {
        margin-right: 103px;
        margin-bottom: 16px;
        max-width: 150px;

        h4 {
          font-weight: 600;
          margin-bottom: 8px;
        }

        li {
          margin: 12px 0;
          font-size: 15px;
          cursor: pointer;
          color: var(--text-color);

          a {
            display: flex;
            color: var(--text-color);
          }
        }
      }
    }
  }

  .footer-bottom {
    p {
      color: var(--subtitle-text-color);
      font-size: 15px;
      font-weight: 500;
      padding-bottom: 31px;
      margin-top: 17px;
    }
  }
}

.external-links {
  padding-top: 35px;
}

.ups-link {
  padding-top: 5px;
}

@media screen and (max-width: 1300px) {
  footer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .footer-links {
    ul {
      margin-right: 25px !important;
    }
  }
}

@media screen and (max-width: 500px) {
  footer {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 60px;

    .footer-top {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .external-links {
    padding-top: 45px;
  }

  .ups-link {
    padding-top: 20px;
  }
}
</style>
