export default {
  "commons": {
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprare e vendere"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il portafoglio"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiedere"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datore"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnala un errore"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di conto"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini attuali"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna a"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudere"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzeramento"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicare"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine del prodotto"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua a"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connessione"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnettersi"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un account"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca e commercio"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamenti"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità notturna"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità trasparente"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia delle transazioni"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il portafoglio"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partite"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storia delle transazioni"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni del conto"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivato"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattivato"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le notifiche"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare tutti"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna notifica per il momento"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile fare clic con il tasto destro del mouse"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo della transazione"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fate troppe richieste"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto il limite di consultazione giornaliero. Controllare il proprio account per evitare ulteriori restrizioni."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla la tua iscrizione:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore, riprovare più tardi"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettuare nuovamente il login"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di convalidare l'e-mail e il telefono prima di eseguire questa azione."])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei autorizzato a eseguire questa azione"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio non disponibile, contattare il servizio clienti"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizie"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliere una lingua"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francese"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglese"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spagnolo"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polacco"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tedesco"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceca"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danese"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greco"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungherese"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giapponese"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coreano"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portoghese"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGNO UNITO"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il russo"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovacco"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinese"])}
    },
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiutare l'abbinamento"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificare che tutti i campi obbligatori siano stati compilati correttamente."])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnala un problema"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquirente"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il venditore"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice inviato"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione: il codice è valido per 15 minuti."])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articoli"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionale"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuale"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafoglio"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portafoglio"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risparmiare"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di controllare la propria iscrizione o di contattare il servizio clienti"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia di successo"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvisi"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azione è stata annullata perché il tempo di attesa è stato superato. Si prega di riprovare"])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conservazione"])},
    "spirit_type_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "storage_tep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino TEP"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per visitare questo sito è necessario avere più di 18 anni"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, controlla la tua età per entrare."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho più di 18 anni"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho meno di 18 anni"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedendo a questo sito, l'utente accetta le seguenti condizioni"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termini e condizioni d'uso"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["informativa sulla privacy."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una rivoluzione in sé"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punto di riferimento per l'acquisto, la vendita e la conservazione degli alcolici"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Che siate amanti del whisky, collezionisti di cognac o professionisti alla ricerca della perfezione, la piattaforma di scambio fa per voi. Sicurezza ottimale, trasparenza totale e commissioni ridotte: scoprite il trading di alcolici in tutta tranquillità."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni sicure"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa ridotta 2,5% IVA inclusa"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conti gratuiti verificati al 100%"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Che tu sia un privato o un professionista, esplora il nostro aggregato di oltre 70 fonti di prezzi su migliaia di referenze di alcolici!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea il tuo account gratuito senza abbonamento"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compravendita: le transazioni sono sicure e trasparenti"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La piattaforma di scambio facilita l'acquisto e la vendita continua. Accedete facilmente a tutte le offerte disponibili grazie ai nostri riferimenti standardizzati, che facilitano il confronto e garantiscono la trasparenza. Trovate quello che cercate o esprimete il vostro interesse: TEP vi permette di far coincidere le vostre esigenze con le ricerche delle controparti, sia che stiate comprando o vendendo. Risparmiate tempo, ottimizzate le vostre transazioni e scambiate sempre al miglior prezzo. Grazie a un'interfaccia intuitiva e a transazioni sicure, TEP semplifica il trading di spiriti, offrendo un'esperienza veloce, affidabile ed efficiente sia ai dilettanti che ai professionisti."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio di bottiglie: sicuro e garantito senza rischio di controparte"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP è l'unica piattaforma che offre la possibilità di scambiare bottiglie in totale sicurezza grazie alle nostre soluzioni di deposito in contanti o in magazzino garantite. Questo sistema garantisce una protezione totale: non c'è rischio di controparte, poiché il denaro è assicurato presso il nostro fornitore di servizi di pagamento fino alla conferma delle rispettive consegne delle bottiglie. Questa caratteristica rivoluziona il mercato degli alcolici, offrendo una trasparenza e un'affidabilità senza precedenti. Che siate collezionisti o intenditori, Exchange vi permette di ampliare o ottimizzare la vostra collezione senza preoccuparvi della sicurezza delle transazioni. Scambiate i vostri liquori in tutta tranquillità, in un ambiente moderno e sicuro."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade: proteggere le transazioni esterne con facilità"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade, una funzione esclusiva di TEP, consente di proteggere le transazioni di acquisto/vendita in pochi clic. Grazie a questa innovazione, viene generato un link unico per ogni transazione, garantendo un'esecuzione affidabile e sicura. Che si tratti di acquisto o di vendita, Block Trade elimina i rischi associati agli scambi diretti con l'esterno, fornendo un quadro trasparente e sicuro. Inoltre, incorpora strumenti avanzati per confermare i termini delle transazioni, verificare i conti e garantire un'esecuzione regolare in ogni fase. Scoprite un nuovo modo di negoziare i vostri spiriti, con la tranquillità e l'affidabilità offerte da TEP."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione del portafoglio: una rivoluzione per collezionisti e investitori"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per i più esperti, la piattaforma di scambio offre uno strumento unico di gestione del portafoglio. Questa funzione consente di monitorare le proprie posizioni e il loro valore in tempo reale utilizzando i prezzi in tempo reale della piattaforma, combinati con i nostri dati storici dettagliati. Niente più domande infinite sul valore della vostra collezione! Con una visione obiettiva e agnostica senza precedenti, potete monitorare l'evoluzione dei vostri asset e prendere decisioni informate. Sia che stiate valutando la vostra collezione o pianificando transazioni strategiche, lo strumento di gestione del portafoglio di TEP trasforma la vostra esperienza fornendo una trasparenza totale e un controllo ottimale sui vostri beni."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi storici: una trasparenza senza pari"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noi di TEP abbiamo compilato lo storico dei prezzi più completo di tutti gli alcolici possibili, con dati che risalgono al 2002! Grazie all'aggregazione di 70 fonti diverse, integrate dalle nostre transazioni, offriamo una visione trasparente e agnostica dei prezzi realizzati in passato. Tutti questi dati sono accuratamente standardizzati e resi disponibili in 18 valute diverse, consentendo ai nostri utenti di confrontare e analizzare le tendenze del mercato su scala globale. Questa funzionalità unica è uno strumento indispensabile per i collezionisti, gli investitori e i professionisti che desiderano prendere decisioni informate, sia che si tratti di acquistare, vendere o semplicemente valutare il proprio portafoglio di liquori."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una garanzia di fiducia"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I pagamenti vengono effettuati tramite il nostro fornitore di servizi di pagamento Mangopay per garantire transazioni senza problemi, senza il rischio che il denaro scompaia e non venga mai inviato alla controparte."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le commissioni più basse del mercato"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbiamo le commissioni più basse del mercato, 2,5% IVA inclusa per acquirente e venditore, nessuna commissione di quotazione o di riserva. Si paga solo se c'è una transazione effettiva."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonimato pre-transazionale"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Che siate privati o professionisti, la nostra piattaforma è la soluzione a tutti i problemi di oggi grazie all'anonimato pre-transazionale."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie al controllo della registrazione da parte del nostro fornitore di servizi di pagamento prima della convalida del conto, nessun conto fantasma, truffatore o bot può entrare nella piattaforma. La tracciabilità e la sicurezza sono totali."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un'offerta su misura per le vostre esigenze"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a una comunità per trovare, gestire e scambiare i tuoi alcolici!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di alcolici"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglia"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, nome, EAN, parole chiave..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca all'interno"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading disponibile senza abbonamento!"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Che siate dilettanti, collezionisti o professionisti, la Piattaforma di scambio è fatta per voi: sicurezza ottimale, trasparenza totale e bassi costi di transazione, con 70 fonti di prezzi storici che coprono migliaia di riferimenti di bottiglie."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoprite il trading di alcolici in tutta tranquillità."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed: il vostro hub di notizie sugli spiriti liberi"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile tenersi aggiornati sulle ultime notizie del settore, sulle nuove uscite, sui principali eventi e sulle tendenze del mercato attraverso il Newsfeed di TEP. Questa funzione consente di seguire in tempo reale tutto ciò che accade nel mondo degli alcolici, con informazioni provenienti dai siti più rilevanti e riconosciuti del settore. Il Newsfeed offre una visione completa e agnostica delle notizie, consentendo di tenersi aggiornati sugli sviluppi del mercato in un unico luogo."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review: il vostro spazio di analisi e scoperta"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esplorate i contenuti di degustazione gratuiti con il Blog & Review di TEP. Con le recensioni di esperti del settore e di appassionati, il Blog & Review vi dà accesso a prospettive uniche e a raccomandazioni affidabili. Sia che vogliate scoprire nuove bottiglie, capire le tendenze del mercato o ampliare le vostre conoscenze, questo feed è pensato per arricchire la vostra esperienza nel mondo degli alcolici."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser degli eventi: la vostra guida agli eventi sugli alcolici"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con l'Events Browser gratuito di TEP, non vi perderete mai un incontro importante nel mondo degli alcolici. Scoprite le fiere e gli eventi esclusivi ovunque vi troviate. Grazie a una selezione accuratamente aggiornata, l'Events Browser vi offre gli eventi più rilevanti del settore, sia che siate collezionisti, investitori o semplici appassionati. Pianificate le vostre uscite e godetevi le esperienze uniche legate agli alcolici."])},
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Entrepôt: immagazzinate i vostri alcolici con TEP"])},
    "storage_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con la nostra soluzione Warehouse, in collaborazione con il nostro fornitore di servizi specializzato, TEP vi offre un servizio di stoccaggio sicuro e professionale per le vostre bottiglie di alcolici. Le nostre strutture dedicate garantiscono condizioni di stoccaggio ottimali, sia per proteggere la vostra collezione, i vostri investimenti o per facilitare le transazioni future."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog e recensioni"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizie"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventi"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzeramento dei filtri"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per ordine alfabetico"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di alcolici"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca di..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrare gli eventi"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eventi in programma"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eventi in programma"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamenti annuali"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffe annuali"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffe mensili"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mese gratuito su un pagamento annuale"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta speciale"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta professionale"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi storici del premium"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per gli utenti che desiderano avere dati storici completi e senza ritardi."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al mese"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all'anno"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per tipo di alcolici"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso a tutti i riferimenti"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi storici senza ritardo"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche avanzate"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriversi a un sito di compravendita di alcolici"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scelta degli alcolici :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I vini"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modulo complementare"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per i collezionisti esperti che vogliono più dati e un vero strumento di gestione."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unisciti a una comunità di specialisti per analizzare, gestire e scambiare i tuoi alcolici!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione avanzata del portafoglio"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegliete questa offerta"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi al carrello"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paniere"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento mensile"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento annuale"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub-totale"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossima campionatura"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedere al pagamento"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo abbonamento è già presente nel tuo carrello"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei già abbonato"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo carrello è vuoto"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna agli abbonamenti"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di contatto"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatturazione"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le transazioni sulla piattaforma di scambio vengono effettuate tramite il nostro fornitore di servizi di pagamento Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta di credito"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di carta"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome sulla carta di pagamento"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza (MM/AA)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di sicurezza"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizzare l'acquisto"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossimamente"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo dei pagamenti"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione di acquisto"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA globale"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonato"])},
    "included_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA inclusa"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il codice di verifica della carta (riportato sul retro della carta, in genere di 3 cifre)"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cancellazione è stata presa in considerazione"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellazione non riuscita, contattare il servizio clienti"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuti sulla piattaforma di scambio"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si può accedere a un ambiente di trading sicuro e standardizzato."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva il mio login"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai dimenticato la password?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristino della password"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceverete un'e-mail con le istruzioni per reimpostare la password."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il tuo indirizzo e-mail"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristino della password"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceverete un'e-mail con le istruzioni per reimpostare la password."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare la password"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di verifica"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppia autenticazione"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il codice ricevuto per e-mail o per telefono"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve contenere 7 caratteri con almeno una lettera maiuscola."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compilare correttamente tutti i campi"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire un codice valido"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un account"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La civiltà"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nazionalità"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero civico"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare la password"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto il"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termini e condizioni d'uso"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto il"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termini e condizioni d'uso"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e il"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla privacy"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de La piattaforma di scambio."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti alla nostra newsletter"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua e-mail"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea il mio account gratuito e continua"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa la mia registrazione"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottenere un account verificato"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della società"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo completo"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email professionale"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di azienda"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero dell'azienda"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita IVA"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mia azienda"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni generali"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllo KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli della banca"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli della banca"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si può accedere a un ambiente di trading sicuro e standardizzato."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il tuo utilizzo"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tipo di conto non può essere cambiato una volta completata la registrazione."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individui"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionale"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto per uso personale"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account professionale verificato"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni generali"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compilare tutti i campi sottostanti"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto privato"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto aziendale"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria professionale"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma giuridica"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I proprietari beneficiari che detengono più del 25%?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di fornire i dettagli di altri beneficiari effettivi che detengono più del 25%."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nato su"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risiedere in"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" e nazionalità"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve contenere almeno 8 caratteri, tra cui una lettera maiuscola, una minuscola, un numero e un carattere speciale."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma dell'e-mail e del numero di telefono"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il codice ricevuto via e-mail"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il codice ricevuto per telefono"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi è stata inviata un'e-mail."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi è stato inviato un SMS."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non avete ricevuto il codice?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare a"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermato"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di conferma"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire un codice valido"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulazioni, avete già accesso a molte delle funzioni del sito gratuitamente!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volete verificare subito la vostra identità per godere di un'esperienza completa?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottenere un account verificato"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottenere un account verificato gratuito"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica della propria identità"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare il tipo di documento"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importare una foto dal passaporto"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importare una foto di entrambi i lati della carta d'identità"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare un file"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File chiaro e leggibile"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione del file max. 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato JPG, JPEG o PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia per la verifica"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tempo medio di verifica è di circa 24 ore."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funzionalità limitata"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completate il processo di registrazione e accedete a tutte le funzionalità."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti inviati"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attualmente in fase di verifica"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile cercare bottiglie e vedere i prezzi storici."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è ancora possibile accedere alla parte transazionale o sottoscrivere abbonamenti."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviato su"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estratto Kbis o documento equivalente con meno di tre mesi di vita."])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia dello statuto"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto o carta d'identità, entrambi i lati"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento inviato:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti inviati:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiutato"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo del rifiuto:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identità personale"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identità aziendale"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuti"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione degli azionisti"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dell'indirizzo"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procedere al pagamento"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di carta non valido"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e cognome sulla carta invalidi"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza non valida"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice carta di credito non valido"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni KYC per le aziende"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I documenti sono stati inviati con successo. La convalida avverrà entro 24 ore."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarazione dei beneficiari effettivi"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tempo medio di verifica è di pochi minuti"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scattare foto chiare e nitide:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurarsi che la carta d'identità o il passaporto siano completamente visibili, senza ombre o riflessi, e che tutti i dettagli siano leggibili."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare in un formato compatibile:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzare un formato JPEG, JPG o PNG, con una risoluzione sufficiente a rendere le informazioni chiaramente visibili."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta il documento completo:"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicuratevi che tutte le pagine necessarie siano incluse separatamente."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario aggiungere 2 prove di identità"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario aggiungere una ricevuta per il Passaporto"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome del file o la dimensione massima di 7 MB per documento sono stati superati."])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli della banca"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserite le vostre coordinate bancarie per ricevere il denaro delle vostre vendite."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I vostri dati saranno soggetti a convalida da parte del nostro servizio clienti. Riceverete un'e-mail quando i vostri dati bancari saranno stati convalidati."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, numero di routing..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di compilare"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentare"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le transazioni sulla piattaforma di scambio vengono effettuate tramite il nostro fornitore di servizi di pagamento Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I vostri dati bancari sono stati salvati"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vostre coordinate bancarie sono già state inviate"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulazioni! Il vostro account è ora verificato."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfogliare le bottiglie"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi al mio portafoglio"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità attiva illimitata"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile accedere all'intera gamma di funzioni."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accetto di ricevere messaggi da The Exchange Platform al numero di telefono sopra indicato. Potrebbero essere applicate tariffe per i dati, rispondere a STOP per annullare l'iscrizione."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se si rifiutano gli SMS 2FA, non sarà possibile confermare il proprio account. È sempre possibile annullare l'iscrizione dopo aver confermato il proprio numero di telefono tramite il nostro sistema SMS 2FA."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di nascita"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese di nascita"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero SIRET"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scansionare o digitare il codice a barre"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerche recenti"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare tutti"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodotto visualizzato"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodotti esposti"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodotto trovato con questa ricerca"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodotti trovati con questa ricerca"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodotto trovato"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prodotti trovati"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manca una bottiglia?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrare per"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza tutti"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprare"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendere"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datore"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercio in blocco"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono state trovate bottiglie corrispondenti alla ricerca."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi la cronologia delle ricerche"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account per sbloccare l'accesso illimitato"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione gratuita"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per questa funzione è richiesto il login"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglia mancante"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della bottiglia"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del sito"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se necessario, specificare i dettagli della bottiglia mancante"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzionale"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato trovato."])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile descrivere i motivi del rifiuto"])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini attuali"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina dell'imbottigliatore"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile utilizzare combinazioni di parole chiave."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ricerca non è sensibile alle maiuscole e alle minuscole."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è necessario specificare gli \"anni\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivere una categoria generica di alcolici (rum, whisky, cognac, ecc.) non produrrà alcun risultato."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificate di avere almeno una parola chiave nella barra di ricerca."])},
    "bottle_in_portolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa bottiglia è nel tuo portafoglio"])},
    "alert_buy_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete attivato un avviso di acquisto su questa bottiglia"])},
    "alert_sell_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete attivato un avviso di vendita per questa bottiglia"])}
  },
  "product_page": {
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi al portafoglio"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto/Vendita"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambio"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercio a blocchi"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sulle bottiglie"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker composito"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di alcolici"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imbottigliatore"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Età"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il marchio"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno di imbottigliamento"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenuti"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di bottiglia"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di bottiglie prodotte"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di produzione"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato della distilleria"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classificazione"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun ordine per questa bottiglia"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker composito"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inattivo"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca e aggiungi una bottiglia al grafico"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker o nome del prodotto"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bottiglia che si sta cercando non è stata trovata"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonti"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna immagine disponibile per questo riferimento"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di botte"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza i prezzi storici"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini in tempo reale"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di bottiglie"])},
    "orders": {
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuale"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miscela"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento non ci sono ordini."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine di acquisto"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo di acquisto"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le opzioni"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmare una data e un'ora di chiusura?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine di acquisto"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine di vendita"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ampiezza"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altezza"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere foto"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le opzioni"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmare una data e un'ora di chiusura?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine di vendita"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine creato con successo"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regole da osservare come"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evitare di ingolfare inutilmente il portafoglio ordini"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitare gli ordini alle transazioni reali."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evitare rifiuti ripetuti"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il rifiuto ripetuto di un incontro può essere percepito come malizioso o irresponsabile. Assicuratevi di effettuare transazioni solo se avete intenzione di portarle a termine."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispettare i tempi di recupero"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ripetuto mancato ritiro dei pacchi in tempo può comportare disagi per il venditore e costi aggiuntivi."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispettare l'integrità del portafoglio ordini"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La manipolazione del portafoglio ordini, la disattivazione di un interesse dopo il rifiuto di una corrispondenza, ecc. sono considerate pratiche ingannevoli. Assicuratevi che le vostre azioni siano trasparenti."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparatevi ai costi associati"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrebbero essere applicate tasse di importazione e doganali locali. Assicuratevi di essere pronti a pagarle quando concludete una transazione."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornire informazioni accurate"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicuratevi che la descrizione della bottiglia sia accurata e veritiera. Questo aiuta gli acquirenti a prendere una decisione informata. Fornite una foto completa del fronte e del retro."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispettare i dettagli dell'offerta"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se vi impegnate a vendere una bottiglia, onorate questo impegno. Ogni offerta non onorata avrà conseguenze per il venditore."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantire la qualità della confezione e del suo contenuto"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le consegne ripetute di pacchi danneggiati (deterioramento, rottura, perdita) e il contenuto non corretto hanno un impatto su di voi. Assicuratevi di imballare con cura le vostre bottiglie."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispettare i tempi di consegna"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ripetuta mancata spedizione nei tempi previsti può compromettere l'accesso all'area delle transazioni. Pianificate di conseguenza per evitare ritardi."])},
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine di acquisto"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine di vendita"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine Swap Taker"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine Swap Giver"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare uno scambio in blocco"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità di peso"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensione dell'unità"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sua richiesta è stata elaborata. Sarai contattato al più presto"])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento del pacchetto:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile dettagliare il problema qui per facilitare la risoluzione del ticket."])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare la ricezione del pacco"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermando la ricezione del pacco, confermate che il prodotto corrisponde alle vostre aspettative."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soppresso"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attesa"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedizione"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare a"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In transito"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegnato"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenzioso"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli sulla consegna"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di tracciamento"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pagamento"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traccia il mio pacco"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi dettagli sulla consegna"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In consegna"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di accettare o rifiutare le proposte prima di creare un nuovo ordine."])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link a Blocktrade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link unico da condividere"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione sicura con il nostro fornitore di pagamenti Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica la bolla di consegna"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In verifica"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione anonima (3 EUR IVA inclusa)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario caricare le immagini della bottiglia"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non si può inserire la stessa immagine più di una volta"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono accettate solo immagini di dimensioni inferiori a 7mb e con estensione .JPG, .JPEG e .png."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordine non può essere valido per meno di un'ora."])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per il momento non ci sono ordini di acquisto."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per il momento non ci sono ordini di vendita."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'acquirente non conosce la vostra identità"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raccolta di casa"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vostro pacco viene ritirato a casa vostra da UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di raccolta"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario del primo ritiro"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo orario di ritiro"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo ordine non corrisponde alle vostre preferenze di paese o a quelle della controparte."])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere immagini"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o trascinare e rilasciare"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo .JPG, .PNG e .JPEG (max 7MB per foto)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibile in punti di scambio"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per accedere alla sezione transazioni è necessario verificare la propria identità."])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controllare la mia identità"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un avviso"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si riceve un'e-mail quando viene creato un ordine di vendita."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si riceve un'e-mail quando viene creato un ordine di acquisto."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si riceve un'e-mail quando viene creato un ordine."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per creare un avviso, accedere alla pagina del prodotto scelto."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi (opzionale)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vostro avviso è stato creato"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella creazione dell'avviso"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il prezzo deve essere compreso tra 50 e 30000 euro o 0 euro."])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metti in pausa questa notifica"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riattiva questo avviso"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disattivato"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia il link per condividere il tuo ordine Blocktrade"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vostro blocktrade è disponibile per un massimo di 2 ore. Per condividere questo ordine di vendita privata, copiare il link cliccando sul pulsante qui sopra."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link di Blocktrade è stato copiato correttamente"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla il tuo ingresso"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo per unità (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensioni del pacchetto"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere una bottiglia"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di scadenza"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è possibile aggiungere due volte lo stesso riferimento"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai raggiunto la quantità massima autorizzata per una singola vendita."])},
      "add_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere un pacco supplementare"])},
      "sell_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listino prezzi"])},
      "min_pictures_required_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È necessario aggiungere almeno"])},
      "min_pictures_required_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" foto"])},
      "storage_subscription_information_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le commissioni sono calcolate su base proporzionale fino alla fine del mese e includono il mese successivo. L'addebito avverrà ogni 1° del mese per"])},
      "storage_enter_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quota di iscrizione al magazzino"])},
      "storage_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino fresco"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi storici"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere una bottiglia"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I grafici sono solo a titolo informativo. Non ci assumiamo alcuna responsabilità per eventuali errori."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nostri dati storici aggregano"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vendita"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vendita"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fonti diverse."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fonte."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuta"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffe"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico di linea"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafico a candela"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni del grafico"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestazioni"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["da"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I prezzi vengono visualizzati con un ritardo di"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mese"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono disponibili dati storici sui prezzi. Se non li abbiamo ancora inseriti, contattate il supporto per farli aggiungere."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista attuale"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giornaliero"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensile"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuale"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimanale"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confronta con"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna bottiglia selezionata"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa bottiglia è già presente nel grafico"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È stato raggiunto il limite di 6 bottiglie per il grafico"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza il composto"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnala un errore"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di errore"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osservazioni"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzionale"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrivete qui il problema in modo più preciso..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schermate / Immagini"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 immagini al massimo."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare"])}
    },
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna bottiglia trovata per questo riferimento."])},
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli sulla consegna"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sua richiesta è stata presa in considerazione"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di uscita"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi di uscita"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partite"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbinare l'acquirente"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbinare il venditore"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprare"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettare e vendere"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visualizza le mie ", _interpolate(_named("num_transac")), " transazioni attuali"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra le foto"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio ordine di acquisto"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquirente"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In corso"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine di vendita"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine di acquisto"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controparte"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio ordine di vendita"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna corrispondenza da visualizzare."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione dell'account e funzioni disponibili per tutti"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caratteristiche"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto non verificato"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account verificato"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizie"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso completo, gratuito e illimitato"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi storici"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione avanzata"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinvio di 15 mesi"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 mesi differiti"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il portafoglio"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazione differita di 15 mesi"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazione differita di 12 mesi"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 ticker unici"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 ticker unici"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Database"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 ticker al giorno"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La negoziazione"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza gli ordini attivi"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun accesso transazionale"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica dell'identità gratuita per i privati e a pagamento per i professionisti. KYC effettuato dal nostro fornitore di servizi di pagamento Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso completo illimitato"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID copiato"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importare un file CSV o Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglia"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona tutti"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselezionare tutti"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca di una bottiglia"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il portafoglio"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per guidarvi,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricare un file"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o trascinare e rilasciare"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo file CSV e XLSX"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un modello di importazione"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo medio di detenzione"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il portafoglio"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinominare"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spostarsi in un'altra cartella"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinominare la cartella"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome del file è richiesto"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero massimo di caratteri non deve superare i 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importatore"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I parametri"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne della tabella"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabella delle opzioni"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le opzioni"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piegare tutti"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri tutti"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra le azioni vendute"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglie"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo netto"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo lordo"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffe"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di stoccaggio"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La partecipazione"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allegati"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove acquistare"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere un file"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere una bottiglia"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della cartella principale"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne aggiuntive"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine a colonna"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie bottiglie"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di acquisto"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere opzioni di prezzo"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di vendita"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dello scambio"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vendita"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprare"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sono state trovate bottiglie corrispondenti alla ricerca."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selezionare la cartella principale"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del file"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un account"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia bottiglia"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del sito"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attuale"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglie detenute"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglie in possesso"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti unici"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglie possedute moltiplicate per il rispettivo prezzo di acquisto"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglie possedute moltiplicate per il valore di mercato"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, differenza tra valore di mercato e prezzo di acquisto moltiplicato per la quantità"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestazioni attuali"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento annuo attuale"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo medio di tutte le bottiglie"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata media della permanenza in portafoglio della partecipazione di un acquirente"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bottiglie acquistate finora"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vostre bottiglie vendute finora"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesa totale ad oggi"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite totali ad oggi"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadagno accumulato dal primo giorno a oggi"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadagno realizzato nel periodo selezionato"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento calcolato dal primo giorno (tiene conto solo delle bottiglie vendute)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento annuo effettivo (bottiglie vendute)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti unici"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore iniziale della posizione"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestazioni attuali"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento annualizzato"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento annuale storico"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento storico realizzato"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo medio"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La storia"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglie acquistate"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker unico"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazione"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadagno latente"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglie vendute"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesa totale"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadagno totale realizzato"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guadagno realizzato (periodo)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendimento storico realizzato"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite totali"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di acquisto/vendita"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo netto"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi esclusi."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi e commissioni"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi con IVA"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo netto"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo lordo di acquisto"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo lordo di vendita"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di stoccaggio"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendite"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli di riferimento"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare questo file?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File Excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerimenti per il vostro file excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne visualizzate"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona tutti"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spazio utilizzato"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un account"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere una bottiglia"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importare un file"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglia attuale"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bottiglie attuali"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento attuale"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti attuali"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier attuale"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spostare in una cartella"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bottiglie nella cartella"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riferimenti nel dossier"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spostare in una cartella"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffe"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partecipazioni (giorno)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allegati"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dove acquistare"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luogo di vendita"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità venduta"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premi WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe di attività"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla scheda prodotto"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo prezzo"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilità"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselezionare tutti"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bottiglia/e selezionata/e"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muoversi"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare la bottiglia"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il portafoglio è attualmente disponibile solo su PC, ma sarà presto disponibile anche su mobile."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilità del portafoglio"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilità degli alcolici"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilità delle distillerie"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilità dell'annata"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Età della volatilità"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premio di portafoglio in arrivo"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi dettagli"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla pagina del prodotto"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File creato"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il file è stato modificato"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il file è stato eliminato"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nella creazione del file"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bottiglia è stata aggiunta"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bottiglia è stata modificata"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bottiglia è stata spostata"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il file è stato importato con successo"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File originale :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cartella e tutte le bottiglie in essa contenute verranno spostate."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo file è stato spostato"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile eliminare le bottiglie presenti in questa cartella."])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia un'offerta"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammessi solo file .jpg, .jpeg e .png di dimensioni inferiori a 3Mb."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli sulle transazioni"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo degli ordini"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costi di spedizione"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tassa"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprare"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendere"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuto"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venditore :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di consegna"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni di transazione"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo totale"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA inclusa a"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di consegna UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione UPS"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicilio"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punti di prelievo disponibili"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva questo indirizzo per acquisti futuri"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transazione non è riuscita. Si prega di riprovare"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transazione è stata completata con successo"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo pagamento è in fase di verifica"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendere qualche secondo"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi al carrello"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi dal carrello"])},
    "storage_exit_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni di uscita"])},
    "storage_exit_payment_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa funzione è in arrivo. Per rilasciare le bottiglie, contattare il servizio clienti."])},
    "inssurance_global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione"])},
    "inssurance_global_include": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione inclusa"])},
    "insurrance_option_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurare le mie bottiglie in caso di danni"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni del conto"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione degli abbonamenti"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I miei abbonamenti"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodo di pagamento"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico delle fatture"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio conto"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisto e vendita"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli della banca"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicurezza e 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'azienda"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La civiltà"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nazionalità"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice postale"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regione"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un altro indirizzo di consegna"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di consegna"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare l'indirizzo di consegna"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di fatturazione"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviato a"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna informazione"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare del conto"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviato su"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia il tuo RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppia autenticazione"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevere un codice ogni volta che si accede al sito e ogni volta che viene apportata una modifica al proprio account."])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppia autenticazione via e-mail"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppia autenticazione via SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultime connessioni"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza la cronologia delle ultime 10 connessioni al tuo account su tutti i dispositivi."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevere la newsletter"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta di debito n."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunto il"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scade il"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiare la mia carta di credito"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non avete ancora registrato una carta di pagamento."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta questo indirizzo come predefinito"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di consegna preferito"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica dell'indirizzo e-mail"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail attuale"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo indirizzo e-mail"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio di numero di telefono"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono attuale"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo numero di telefono"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungere un indirizzo di consegna"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email errata"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono errato"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già questo indirizzo e-mail"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avete già questo numero di telefono"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesa del mese corrente"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonato da"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei abbonato"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamenti annuali"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prossima data di raccolta"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonato fino a"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento dell'iscrizione"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esplora gli abbonamenti ai prezzi storici disponibili"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullamento dell'iscrizione"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'abbonamento scade il"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento interessato"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare la cancellazione"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento al curriculum"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuare l'abbonamento"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il prossimo pagamento sarà effettuato il"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento interessato"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pagamento verrà effettuato ogni"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al prezzo di"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare la ripresa"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impegnato fino a"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mese"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi storici del premium"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico delle fatture"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica tutti"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I prezzi"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbonamento"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica la fattura"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pagamento"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non si dispone di una fattura"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli sulla consegna"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traccia il pacchetto"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna stimata"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° transazione"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il codice ricevuto via e-mail"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il codice ricevuto per telefono"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova e-mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo telefono"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il codice ricevuto via e-mail"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserire il codice ricevuto per telefono"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fattura e indirizzo di consegna"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vostre preferenze sono state registrate con successo"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città di nascita"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese di nascita"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le notifiche"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine di acquisto"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine di vendita"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine di scambio"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare un ordine di blocktrade"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un acquirente ha rifiutato l'offerta"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine di vendita in attesa di convalida"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzare la ricezione di SMS (convalida dell'account e/o 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferenze di comunicazione"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punto di collegamento UPS"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni di acquisto"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni di vendita"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilità di acquisto"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilità delle vendite"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi da escludere"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paesi da includere"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiare le mie preferenze di acquisto per le vendite"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vostre modifiche sono state prese in considerazione"])}
    },
    "update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare le informazioni del conto"])},
    "wrong_pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla il tuo nome utente"])},
    "catch_update_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile aggiornare le informazioni. Contattare l'assistenza se il problema persiste."])},
    "pseudo_already_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo nome utente non è più disponibile, sceglietene un altro."])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reti sociali"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legale"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro assistenza"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reclutamento"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segnala un bug"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato dei servizi"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni legali"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERMINI E CONDIZIONI"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione dei cookie"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["società registrata in Francia"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla privacy"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di consegna"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasse"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprare"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuto"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo degli ordini"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parte venduta :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di consegna"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese di vendita"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo totale"])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scambiatore"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di conto"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordini attuali"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direzione"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I prezzi"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare l'ordine di acquisto"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare l'ordine di vendita"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare il commercio a blocchi"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificare l'ordine di swap"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra gli ordini cancellati"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desidero ricevere un"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possiedo un"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in cambio di"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa vorrei scambiare con"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine modificato con successo"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine creato con successo"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo totale :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ordini :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo, prezzo..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo del rifiuto"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifiuto"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenzioso in corso"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dichiarare una controversia"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non vengono effettuate transazioni."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzeramento dei filtri"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pubblicato su :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificato il :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrare gli articoli"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun elemento trovato."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo disponibile"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo totale"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I movimenti"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento effettuato"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione da visualizzare."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento in entrata"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento in uscita"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento del venditore"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prelievo sul mio conto bancario"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prelievo dei fondi disponibili"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare il prelievo"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il trasferimento sul vostro conto bancario è stato completato con successo."])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il trasferimento al suo conto non è riuscito. Se il problema persiste, contattare il Servizio clienti."])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio portafoglio (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo rimanente dopo la transazione :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento ricevuto"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi alla mia storia di trading"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferire denaro direttamente al proprio portafoglio per evitare le commissioni di pagamento."])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietario :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I fondi saranno disponibili entro 2 o 3 giorni lavorativi."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento copiato con successo"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornitura del portafoglio"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il pagamento avviene di default tramite bonifico istantaneo"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo disponibile per il prelievo :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto bancario che termina con :"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri avanzati"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono necessari 2 filtri per le ricerche senza parole chiave"])}
  },
  "ratings": {
    "ratings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazioni"])},
    "ratings_phone_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valutazioni :"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione del sito"])},
    "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descrizione può contenere fino a 500 caratteri"])},
    "create_error_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificare che la valutazione sia compresa tra 1 e 100 e che la descrizione sia lunga meno di 500 caratteri."])},
    "create_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare una valutazione"])},
    "create_btn_long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creare una valutazione"])},
    "see_or_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza o modifica la valutazione"])},
    "update_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia la valutazione"])},
    "delete_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellare la valutazione"])},
    "rating_explain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per creare una valutazione, andare alla pagina di un prodotto"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria, nome, punteggio..."])},
    "description_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descrizione è privata. Non sarà disponibile pubblicamente"])},
    "not_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opzionale"])},
    "rating_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero minimo di valutazioni non raggiunto per fare una media."])},
    "pseudo_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da 5 a 50 caratteri (si accettano lettere e numeri)"])},
    "empty_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non c'è ancora una valutazione per questa bottiglia."])},
    "ranking_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il punto di partenza per tutti gli utenti."])},
    "ranking_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riconosce i collaboratori regolari."])},
    "ranking_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica un impegno e una competenza avanzati."])},
    "ranking_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un impegno concreto."])},
    "ranking_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riconosce un ruolo di leadership nella comunità."])},
    "ranking_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo per utenti molto attivi."])},
    "ranking_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ultimo rango, per i collaboratori più impegnati."])},
    "pseudo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soprannome"])},
    "private_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendi privato il mio commento"])},
    "profil_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "rating_unitary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valutazione"])},
    "rating_multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valutazioni"])}
  },
  "storage": {
    "storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conservazione"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo, riferimento..."])},
    "invoice_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica la fattura di acquisto"])},
    "check_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzare o modificare la bottiglia"])},
    "store_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di stoccaggio"])},
    "exit_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tira fuori le mie bottiglie"])},
    "supplier_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino"])},
    "reference_u": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riferimento"])},
    "reference_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riferimenti"])}
  },
  "order_renew": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo ordine è stato rinnovato con successo"])}
  }
}