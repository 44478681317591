import { api } from '@/services/interceptors';
// import { ElMessage } from 'element-plus';
// import i18n from '../i18n';

export default {
  state: {
    bottles: [],
    bottle: null,
    ratings: null,
  },
  getters: {
    getStorageBottles(state) {
      return state.bottles;
    },
    getStorageBottle(state) {
      return state.bottle;
    },
    getRatings(state) {
      return state.ratings;
    },
  },
  actions: {
    storageBottles({ commit }) {
      return new Promise((resolve) => {
        api.get('api/auth/storage/bottles').then((res) => {                      
            commit('STORAGE_BOTTLES', res.data.storageBottles);
            resolve();
        });
      })
    },
    storageBottle({ commit }, payload) {
        return new Promise((resolve) => {
          api.get('api/auth/storage/bottle/' + payload.bottle_uuid).then((res) => {
              commit('STORAGE_BOTTLE', res.data.storageBottles);
              resolve();
          });
        })
      },
      pickingRating({ commit }, payload) {
        return new Promise((resolve) => {
          api.post('api/auth/storage/picking/rating', payload).then((res) => {            
            commit('STORAGE_PICKING_RATING', res.data.rating);
            resolve();
          });
        })
      },
    // updateReview({ commit, dispatch }, payload) {
    //     commit('STORE_NOTHING');
    //     return new Promise((resolve) => {
    //         api.put('api/auth/reviews', payload).then(() => {
    //           dispatch('reviews');
    //           resolve();
    //         });
    //     })
    //   },
    // deleteReview({ commit, dispatch }, payload) {
    //   commit('STORE_NOTHING');
    //   api.delete('api/auth/reviews/' + payload).then(() => {
    //     dispatch('reviews');
    //   });
    // },
  },
  mutations: {
    STORAGE_BOTTLES(currentState, bottles) {
      currentState.bottles = bottles;
    },
    STORAGE_BOTTLE(currentState, bottle) {
      currentState.bottle = bottle;
    },
    STORAGE_PICKING_RATING(currentState, rating) {
      currentState.ratings = rating;
    },
    STORE_NOTHING() { }
  }
};